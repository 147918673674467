import * as ADMISSION from './admission';
import * as MODALIDAD from './modalidad';
import config from '../config';

const promo = config.current_promo.toUpperCase();

const starting = new Date('2022-09-22 08:30');
const end = new Date('2023-01-04');
const startingDate = `${starting.toLocaleDateString('es-ES', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
})} a las ${starting.toLocaleTimeString([], {
  hour: '2-digit',
  minute: '2-digit',
})}`;
const endDate = end.toLocaleDateString('es-ES', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});
const address = 'Talent Garden, Calle Juan de Mariana 15, 28045';
const horarioMañana = '08:30 a 14:00';
const horarioTarde = '16:00 a 17:30';
const accountNumber = 'ES17 0073 0100 5305 0510 5489';
const URLPDF = 'https://adalab.es/downloads/financiacion-alumnas.pdf';

const formato = assignedSchedule => {
  const sentences = {
    [ADMISSION.SEMIPRESENCIAL_PRESENCIAL]: `en tu caso semipresencial, realizando la mitad del curso online y la otra mitad de forma presencial, comenzando de forma presencial. En ambos formatos la asistencia es obligatoria de ${horarioMañana} (programación) y 9 sesiones de desarrollo profesional (siempre en formato online en directo) de ${horarioTarde}. Las clases presenciales son en ${address}, Madrid. Además, de media las alumnas dedican unas 4 horas más al día por su cuenta.`,
    [ADMISSION.SEMIPRESENCIAL_ONLINE]: `en tu caso semipresencial, realizando la mitad del curso online y la otra mitad de forma presencial, comenzando de forma online. En ambos formatos la asistencia es obligatoria de ${horarioMañana} (programación) y 9 sesiones de desarrollo profesional (siempre en formato online en directo) de ${horarioTarde}. Las clases presenciales son en ${address}, Madrid. Además, de media las alumnas dedican unas 4 horas más al día por su cuenta.`,
    [ADMISSION.ONLINE]: `en tu caso online, y las clases son de asistencia obligatoria de ${horarioMañana} (programación) y 9 sesiones de desarrollo profesional de ${horarioTarde}. Además, de media las alumnas dedican unas 4 horas más al día por su cuenta.`,
    [ADMISSION.SIN_DEFINIR]: `en tu caso pendiente de definir al reservar la plaza, y las clases son de asistencia obligatoria de ${horarioMañana} (programación) y de ${horarioTarde} (9 sesiones de desarrollo profesional). Además, de media las alumnas dedican unas 4 horas más al día por su cuenta.`,
  };
  return sentences[assignedSchedule];
};

const financiacionInstrucciones = modalidadDePago =>
  modalidadDePago === MODALIDAD.FINANCIACION ||
  modalidadDePago === MODALIDAD.FINANCIACION_NEW
    ? `Como has señalado que quieres solicitar financiación para pagar el curso puedes ver las opciones disponibles y condiciones aquí ${URLPDF}.  En las próximas 24 horas nos pondremos en contacto contigo para agendar una videollamada para explicarte en detalle las opciones disponibles para que puedas elegir la que más te conviene.`
    : '‏‏‎ ‎  ';

const precioCurso = modalidadDePago =>
  modalidadDePago === MODALIDAD.A_PLAZOS
    ? 'de la reserva de plaza de 420 €'
    : 'del curso de 3.990€';

const condicionesEconomicas = (modalidadDePago, daysToAccept) =>
  modalidadDePago === MODALIDAD.A_PLAZOS
    ? `El importe total del curso asciende a 6.620 €.  Sin embargo, puesto que nuestro objetivo es hacerlo accesible al mayor número de mujeres, cada alumna recibe una beca de 2.000 € para estudiar con nosotros. Así que por el hecho de haber sido admitida en Adalab, ya cuentas con esta beca. ¡Enhorabuena! 
Deberás, por lo tanto abonar los 4.620 € restantes de la siguiente manera:

  • En los ${daysToAccept} días naturales posteriores al envío por mail de esta carta, la alumna deberá pagar la cuota de reserva de la plaza. Esta cuota asciende a 420 €. Con el pago de esta cuota se garantiza la plaza de la alumna en el curso. 

  • En los 5 días naturales tras la conclusión del Curso Intensivo, la alumna deberá abonar la primera de las 10 cuotas mensuales de 420 €, ascendiendo la cantidad total a 4.200 €.
`
    : `El importe total del curso asciende a 6.620 €.  Sin embargo, puesto que nuestro objetivo es hacerlo accesible al mayor número de mujeres, cada alumna recibe una beca de 2.000 € para estudiar con nosotros. Así que por el hecho de haber sido admitida en Adalab, ya cuentas con esta beca. ¡Enhorabuena! Además, ahora te puedes beneficiar de un descuento de 630 € por pagar al contado, por lo que para confirmar y reservar tu plaza deberás abonar los 3.990 € restantes.`;

const aclaraciones = modalidadDePago =>
  modalidadDePago === MODALIDAD.A_PLAZOS
    ? `  • Una vez confirmada la plaza, si la alumna decide abandonar el programa antes del inicio del Curso Intensivo, se le retendrá la cuota de confirmación de plaza por gastos de gestión, es decir 420 €.
  • Si la alumna decide abandonar el programa una vez iniciado el Curso Intensivo, tendrá que abonar la totalidad del importe que le quede por pagar, es decir 10 cuotas mensuales de 420 € empezando en el mismo momento que se comunica la decisión de no continuidad. 
  • Si la alumna no pudiera continuar el Curso Intensivo por indicación del equipo de Adalab al no estar cumpliendo con sus responsabilidades como alumna, se le retendrá la cuota de confirmación de plaza por gastos de gestión, es decir 420 €.
  • Si la alumna no superase las evaluaciones en alguna de las dos oportunidades disponibles por módulo, podrá continuar en el Curso Intensivo pero no podrá optar a buscar empleo a través de la Bolsa de Empleo de Adalab. 
`
    : `  • Una vez confirmada la plaza, si la alumna decide abandonar el programa antes del inicio del Curso Intensivo, se le retendrán 420 € por gastos de gestión del total pagado, reembolsando la diferencia.
  • Si la alumna decide abandonar el programa una vez iniciado el Curso Intensivo, no se le reembolsará el importe del curso.
  • Si la alumna no pudiera continuar el Curso Intensivo por indicación del equipo de Adalab al no estar cumpliendo con sus responsabilidades como alumna, se le retendrán 420 € por gastos de gestión del total pagado, reembolsando la diferencia.
  • Si la alumna no superase las evaluaciones en alguna de las dos oportunidades disponibles por módulo, podrá continuar en el Curso Intensivo pero no podrá optar a buscar empleo a través de la Bolsa de Empleo de Adalab. 
`;

const modInicial = assignedSchedule => {
  const text = {
    [ADMISSION.SEMIPRESENCIAL_PRESENCIAL]: `semipresencial`,
    [ADMISSION.SEMIPRESENCIAL_ONLINE]: `semipresencial`,
    [ADMISSION.ONLINE]: `online`,
    [ADMISSION.SIN_DEFINIR]: `pendiente de definir al reservar la plaza`,
  };

  return text[assignedSchedule];
};

const onlySemiPresential = assignedSchedule => {
  const text = {
    [ADMISSION.SEMIPRESENCIAL_PRESENCIAL]:
      '. Cuando se acerque la fecha de inicio del curso al que has aplicado volveremos a analizar la situación y te informaremos en caso de que podamos volver al formato semipresencial. En cualquier caso garantizamos la misma calidad del curso en ambos formatos.',
    [ADMISSION.SEMIPRESENCIAL_ONLINE]:
      '. Cuando se acerque la fecha de inicio del curso al que has aplicado volveremos a analizar la situación y te informaremos en caso de que podamos volver al formato semipresencial. En cualquier caso garantizamos la misma calidad del curso en ambos formatos.',
    [ADMISSION.ONLINE]: '.',
    [ADMISSION.SIN_DEFINIR]: '.',
  };

  return text[assignedSchedule];
};

export const generateDynamicLetter = (
  name,
  modalidadDePago,
  assignedSchedule,
  daysToAccept = 10
) => {
  const today = new Date();
  const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = today.toLocaleDateString('es-ES', dateFormatOptions);

  const dynamicLetter = {
    name,
    date,
    promo,
    modalidadDePago,
    assignedSchedule: assignedSchedule.toLowerCase(),
    startingDate,
    address,
    formato: formato(assignedSchedule),
    modalidadInicial: modInicial(assignedSchedule),
    accountNumber,
    daysToAccept,
    endDate,
    financiacionInstrucciones: financiacionInstrucciones(modalidadDePago),
    precioCurso: precioCurso(modalidadDePago),
    condicionesEconomicas: condicionesEconomicas(modalidadDePago, daysToAccept),
    aclaraciones: aclaraciones(modalidadDePago),
    onlySemiPresential: onlySemiPresential(assignedSchedule),
  };

  return dynamicLetter;
};
