export const WORD_OF_MOUTH = 'Boca a boca';
export const TWITTER = 'Twitter';
export const FACEBOOK = 'Facebook';
export const INSTAGRAM = 'Instagram';
export const LINKEDIN = 'Linkedin';
export const INJUVE = 'INJUVE';
export const ONG = 'ONG';
export const MEDIA = 'Medios de comunicación (prensa, radio, etc.,)';
export const ADS = 'Publicidad';
export const OTHER = 'Otro medio';

export const ALL = [
  WORD_OF_MOUTH,
  TWITTER,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  INJUVE,
  ONG,
  MEDIA,
  ADS,
  OTHER,
];