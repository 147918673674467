export const ADELANTADO = 'Pago por adelantado';
export const DURANTE_EL_CURSO = 'Pago durante el curso';
export const HIBRIDO = 'Pago híbrido';
export const DIFERIDO = 'Pago diferido y fraccionado';

export const ALL = [
  ADELANTADO,
  DURANTE_EL_CURSO,
  HIBRIDO,
  DIFERIDO,
];

export const PRIMER_PAGO = {
  [ADELANTADO]: '2.180 €',
  [DURANTE_EL_CURSO]: '900 €',
  [HIBRIDO]: '900 €',
  [DIFERIDO]: '350€',
}

export const TOTAL = {
  [ADELANTADO]: '2.180 €',
  [DURANTE_EL_CURSO]: '2.380 €',
  [HIBRIDO]: '2.530 ',
  [DIFERIDO]: '2.630 €',
}

export const AL_CONTADO = 'Pago al contado con recursos propios'
export const AL_CONTADO_NEW = 'Pago al contado'
export const FINANCIACION = 'Pago al contado con financiación de Banc Sabadell'
export const FINANCIACION_NEW = 'Quiero solicitar financiación'
export const A_PLAZOS = 'No me puedo permitir otra opción y solicitaría el pago a plazos'

export const TODAS = [
  AL_CONTADO,
  AL_CONTADO_NEW,
  FINANCIACION,
  FINANCIACION_NEW,
  A_PLAZOS,
]