import React from 'react';
import PropTypes from 'prop-types';
import { getTheDate } from '../../helpers'

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const PersonalData = ({
  email,
  phone,
  zip,
  age,
  isAbleToWorkInSpain,
  workStatus,
  studies,
  studiesDescription,
  prevKnowledge,
  preferredSchedule,
  applyForScholarship,
  dni,
  inscriptionDate,
  assignedSchedule,
  modalidadDePago,
  modalidadDePago2,
  modalidadComentarios,
}) => (
  <div className="container">
    <Grid container  spacing={2}>
      <Grid item xs={4}>
        <List>
          <ListItem>
            <ListItemText primary="Email" secondary={email} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Teléfono" secondary={phone} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Código postal" secondary={zip} />
          </ListItem>
          <ListItem>
            <ListItemText primary="DNI" secondary={dni} />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={4}>
        <List>
          <ListItem>
            <ListItemText primary="Edad" secondary={age} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Permiso de trabajo"
              secondary={isAbleToWorkInSpain}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Situación laboral" secondary={workStatus} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Solicita Beca"
              secondary={applyForScholarship === 'Sí' ? 'Sí' : 'No'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Modalidad de pago"
              secondary={modalidadDePago}
            />
          </ListItem>
          {
            modalidadDePago2 && (
              <ListItem>
                <ListItemText
                  primary="Modalidad de pago secundaria"
                  secondary={modalidadDePago2}
                />
              </ListItem>
            )
          }
          {
            modalidadComentarios && (
              <ListItem>
                <ListItemText
                  primary="Comentarios modalidad de pago"
                  secondary={modalidadComentarios}
                />
              </ListItem>
            )
          }
        </List>
      </Grid>
      <Grid item xs={4}>
        <List>
          <ListItem>
            <ListItemText
              primary="Estudios"
              secondary={`${studies}: ${studiesDescription}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Conocimientos previos"
              secondary={prevKnowledge}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Preferencia de horario"
              secondary={preferredSchedule}
            />
          </ListItem>
          {
            assignedSchedule && (
              <ListItem>
                <ListItemText
                  primary="Horario asignado"
                  secondary={assignedSchedule}
                />
              </ListItem>
            )
          }
          <ListItem>
            <ListItemText
              primary="Fecha de inscçripción"
              secondary={
                inscriptionDate &&
                getTheDate(inscriptionDate).toLocaleString('es-ES')
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  </div>
);

PersonalData.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  zip: PropTypes.string,
  age: PropTypes.number,
  isAbleToWorkInSpain: PropTypes.string,
  workStatus: PropTypes.string,
  studies: PropTypes.string,
  studiesDescription: PropTypes.string,
  prevKnowledge: PropTypes.string,
  preferredSchedule: PropTypes.string,
  applyForScholarship: PropTypes.string,
  dni: PropTypes.string,
};

export default PersonalData;
