import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const getDNIURLs = dniURLs =>
  dniURLs ? dniURLs.split(',').map(s => s.trim()) : [];

const AdmissionData = ({
  fullAddress,
  city,
  useLaptop,
  photoURL,
  dni,
  dniURL,
  assignedSchedule,
  scholarship,
  scholarshipAmount,
  proofOfPaymentURL,
  nationality,
  hasChildren,
}) => (
  <div className="container">
    <Grid container  spacing={2}>
      <Grid item xs={4}>
        <List>
          <ListItem>
            <ListItemText primary="Dirección" secondary={fullAddress} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Localidad" secondary={city} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Foto"
              secondary={<a href={photoURL}>Ver en Google Drive</a>}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Justificante de pago"
              secondary={<a href={proofOfPaymentURL}>Ver en Google Drive</a>}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={4}>
        <List>
          <ListItem>
            <ListItemText primary="DNI" secondary={dni} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Fotos DNI"
              secondary={
                <ul>
                  {getDNIURLs(dniURL).map((url, index) => (
                    <li key={url}>
                      <a href={url}>Documento ({index + 1})</a>
                    </li>
                  ))}
                </ul>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Uso de ordenador" secondary={useLaptop} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Turno asignado"
              secondary={assignedSchedule ? assignedSchedule : 'Por definir'}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={4}>
        <List>
          <ListItem>
            <ListItemText
              primary="Beca"
              secondary={scholarship ? 'Sí' : 'No'}
            />
          </ListItem>
          {scholarship && (
            <ListItem>
              <ListItemText
                primary="Importe de la beca"
                secondary={scholarshipAmount ? scholarshipAmount : 400}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText primary="Nacionalidad" secondary={nationality} />
          </ListItem>
          <ListItem>
            <ListItemText primary="¿Tiene hijos?" secondary={hasChildren} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  </div>
);

AdmissionData.propTypes = {
  fullAddress: PropTypes.string,
  city: PropTypes.string,
  useLaptop: PropTypes.string,
  photoURL: PropTypes.string,
  dni: PropTypes.string,
  dniURL: PropTypes.string,
  assignedSchedule: PropTypes.string,
  scholarship: PropTypes.string,
  scholarshipAmount: PropTypes.string,
  proofOfPaymentURL: PropTypes.string,
  nationality: PropTypes.string,
  hasChildren: PropTypes.string,
};

export default AdmissionData;
