import * as STATUS from '../constants/status';
import * as PROGRESS from '../constants/progress';
import * as ADMISSION from '../constants/admission';
import * as KNOWLEDGE from '../constants/prevKnowledge';
import * as STUDIES from '../constants/studies';
import * as MODALIDAD from '../constants/modalidad';
import processor from './index';
import { getTheDate } from '../helpers'


const twoDigitsPercentage = num => (num * 100).toFixed(2);

const incrementPercentage = num => twoDigitsPercentage(num - 1);

const calculateDayBeetweenDates = (start, end) =>
  (end - start) / (1000 * 60 * 60 * 24);

const calculateTotal = candidates => candidates.length;

const filterDuplicates = candidates => candidates
// const filterDuplicates = candidates => [...new Set(candidates.map(candy => candy.email))]

// Total de inscritas
const calculateTotalRegistered = (candidates, promoDates) => {
  const candys = filterDuplicates(
    candidates
      // .filter(c => c.inscriptionDate)
      .filter(
        c =>
          Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) > promoDates.endOfPrevious
          // && Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) < promoDates.end
      )
  )
  // console.log('Total', candys)
  return candys.length
}

const calculateTotalBetweenPromos = (candidates, promoDates, users = []) => {
  const candys = filterDuplicates(
    candidates.filter(
      c =>
        Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) > promoDates.endOfPrevious &&
        Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) < promoDates.start
    )
  )

  // console.log('Entre proocesos', candys)
  return candys.length
}

const calculateTotalInCurrentPromo = (candidates, promoDates, users = []) => {
  
  const candys = candidates.filter(
    c =>
      Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) > promoDates.start
      // && Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) < promoDates.end
  )

  // console.log('Nuevas', candys)
  return filterDuplicates(candys).length
}


const calculateTotalOld = (candidates, promoDates) =>
  calculateTotal(candidates) -
  calculateTotalBetweenPromos(candidates, promoDates) -
  calculateTotalInCurrentPromo(candidates, promoDates);

const calculateRatioBetweenPromos = (candidates, promoDates, users = []) => {
  const dateOfLastDay =
    Date.now() < promoDates.start ? Date.now() : promoDates.start;

  return (
    calculateTotalBetweenPromos(candidates, promoDates, users) /
    calculateDayBeetweenDates(promoDates.endOfPrevious, dateOfLastDay)
  ).toFixed(2);
};

const calculateRatioInCurrentPromo = (candidates, promoDates, users = []) => {
  const dateOfLastDay =
    Date.now() < promoDates.end ? Date.now() : promoDates.end;
  return (
    calculateTotalInCurrentPromo(candidates, promoDates, users) /
    calculateDayBeetweenDates(promoDates.start, dateOfLastDay)
  ).toFixed(2);
};

const calculateOKRegistered = (candidates, promoDates) =>{
  const candys = filterDuplicates(
    candidates.filter(
      c =>
        Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) > promoDates.endOfPrevious
        // && Date.parse(getTheDate(c.inscriptionDate)) < promoDates.end
    )
    .filter(
      c =>
        c.status === STATUS.APPLICATION_OK ||
        c.status === STATUS.APPLICATION_PRE_OK
    )
  )

  // console.log('OK Inscritas', candys)
  return candys.length;
}

const calculateNotOKRegistered = (candidates, promoDates) => {
  const candys = filterDuplicates(
    candidates
      .filter(
        c =>
          Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) > promoDates.endOfPrevious
          // && Date.parse(getTheDate(c.inscriptionDate)) < promoDates.end
      )
      .filter(
        c =>
          c.status !== STATUS.APPLICATION_OK &&
          c.status !== STATUS.APPLICATION_PRE_OK
      )
  )
  // console.log('Not OK inscritas', candys)
  return candys.length;
}


// const activasFilter = (c, promoDates) => Date.parse(getTheDate(c.inscriptionDate)) > promoDates.start
const activasFilter = (c, promoDates) => {
  return Date.parse(getTheDate(c.creationTimestamp)) >= promoDates.start
}


const calculateActive = (candidates, promoDates) => {
  const candys = filterDuplicates(
    candidates.filter(c => activasFilter(c, promoDates))
  )
  return candys.length;
}

const calculateOldActive = (candidates, promoDates) => {
  const candys = candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(
        c =>
          Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) <
          promoDates.endOfPrevious
      )

  return filterDuplicates(candys).length;
}

const calculateBetweenProcessesActive = (candidates, promoDates) => {
  const candys = candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(
        c =>
          Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) >
          promoDates.endOfPrevious &&
          Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) <
            promoDates.start
      )
  // console.log('Entre procesos', filterDuplicates(candys))
  return filterDuplicates(candys).length;
}

const calculateNewActive = (candidates, promoDates) => {
  const candys = candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(
        c =>
          Date.parse(getTheDate(c.inscriptionDate || c.creationTimestamp)) > promoDates.start
      )
  // console.log('Nuevas', filterDuplicates(candys))
  return filterDuplicates(candys).length;
}




  
// PROGRESS ?
const calculateOKActive = (candidates, promoDates) =>
  filterDuplicates(
    candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(c => c.status === STATUS.APPLICATION_OK)
  ).length

const calculateOKActiveNotStarted = (candidates, promoDates) =>
  filterDuplicates(
    candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(c => c.status === STATUS.APPLICATION_OK)
      .filter(c => !c.progress)
  ).length;

  const calculateOKActiveStarted = (candidates, promoDates) => {
    const candys = filterDuplicates(
      candidates
        .filter(c => activasFilter(c, promoDates))
        .filter(c => c.status === STATUS.APPLICATION_OK)
        .filter(c => c.progress)
    )
    return candys.length;
  }
  

const calculateOKActiveOnlineTests = (candidates, promoDates) =>
  filterDuplicates(
    candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(c => c.status === STATUS.APPLICATION_OK)
      .filter(c => c.htmlScore && c.jsScore)
  ).length;

const calculateOKActiveF2FTests = (candidates, promoDates) => {
  const candys = filterDuplicates(
    candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(c => c.status === STATUS.APPLICATION_OK)
      .filter(c => c.teaResults)
  )

  console.log(candys.map(c => c.email))
  return candys.length
};

const calculateOKActiveInterview = (candidates, promoDates) =>
  filterDuplicates(
    candidates
      .filter(c => activasFilter(c, promoDates))
      .filter(
        c =>
          c.status === STATUS.APPLICATION_OK ||
          c.status === STATUS.APPLICATION_PRE_OK
      )
      .filter(c => c.interviewScore)
  ).length;

const calculateOK = candidates =>
  filterDuplicates(
    candidates.filter(
      c =>
        c.status === STATUS.APPLICATION_OK ||
        c.status === STATUS.APPLICATION_PRE_OK
    )
  ).length;

const calculateNotOK = candidates =>
  filterDuplicates(
    candidates.filter(
      c =>
        c.status !== STATUS.APPLICATION_OK &&
        c.status !== STATUS.APPLICATION_PRE_OK
    )
  ).length;

const calculateProcessCompleted = candidates =>
  filterDuplicates(
    candidates.filter(c => c.admission)
  ).length;

const calculateProcessCompletedWithInterview = candidates =>
  filterDuplicates(
    candidates.filter(
      c => c.progress === PROGRESS.PROCESS_COMPLETED && c.interviewScore
    )
  ).length;

const calculateNonAdmitted = candidates => {
  const candys = filterDuplicates(
    candidates.filter(
      c => c.admission && ADMISSION.ALL_NON_ADMITTED.includes(c.admission)
    )
  )
  return candys.length;
}
  

const calculateNonAdmittedOnlineTests = candidates =>
  filterDuplicates(
    candidates.filter(
      c => ADMISSION.ALL_NON_ADMITTED.includes(c.admission) && !c.teaResults
    )
  ).length;

const calculateNonAdmittedF2FTests = candidates =>
  filterDuplicates(
    candidates.filter(
      c =>
        ADMISSION.ALL_NON_ADMITTED.includes(c.admission) &&
        c.teaResults &&
        !c.interviewScore
    )
  ).length;

const calculateNonAdmittedAfterInterview = candidates =>
  filterDuplicates(
    candidates.filter(
      c => ADMISSION.ALL_NON_ADMITTED.includes(c.admission) && c.interviewScore
    )
  ).length;

const calculateAdmitted = candidates =>
  filterDuplicates(
    candidates.filter(
      c => c.admission && c.admission.startsWith(ADMISSION.ADMITTED)
    )
  ).length;

const calculateALLAdmitted = candidates =>
  candidates.filter(
    c => c.admission && c.admission.startsWith(ADMISSION.ADMITTED)
  );

const calculateAdmittedNotConfirmed = candidates =>
  candidates.filter(c => c.admission === ADMISSION.ADMITTED).length;

const calculateAdmittedConfirmed = candidates =>
  candidates.filter(c => c.admission === ADMISSION.ADMITTED_CONFIRMED).length;

const calculateAdmittedRejected = candidates =>
  candidates.filter(c => c.admission === ADMISSION.ADMITTED_REJECTED).length;

const calculateAdmittedNotRejected = candidates =>
  candidates.filter(c => ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission))
    .length;

const calculateWaitingList = candidates =>
  candidates.filter(c => c.admission === ADMISSION.WAITING_LIST).length;

const calculateAdmittedWithNoHigherStudies = candidates =>
  candidates.filter(
    c =>
      ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission) &&
      !STUDIES.universityStudies.includes(c.studies)
  ).length;

const calculateAdmittedWithNoPrevKnowledge = candidates =>
  candidates.filter(
    c =>
      ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission) &&
      KNOWLEDGE.lowPrevKnowledge.includes(c.prevKnowledge)
  ).length;

const calculateAdmittedOlderThan34 = candidates =>
  candidates.filter(
    c => ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission) && c.age > 34
  ).length;

const calculateAdmittedWithEnglishLevelA = candidates =>
  candidates.filter(
    c =>
      ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission) &&
      c.englishLevel &&
      c.englishLevel.startsWith('A')
  ).length;

const calculateAdmittedWithBATOf2 = candidates =>
  candidates.filter(c => c.admission && c.teaResults)
    .filter(
    c => {
      return ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission) &&
        processor.caculateMarkForBAT7(c.teaResults) === 2
    }
  ).length;

const calculateAdmittedWithPrevKnowledge = candidates =>
  candidates.filter(
    c =>
      ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission) &&
      KNOWLEDGE.realPrevKnowledge.includes(c.prevKnowledge)
  ).length;

const ageCalculator = candidate => candidate.age;
const interviewCalculator = candidate => candidate.interviewScore;
const batCalculator = candidate =>
  processor.caculateMarkForBAT7(candidate.teaResults);

const calculateAverageOfAdmitted = calculator => candidates => {
  const admitted = candidates
  .filter(c => c.admission && c.teaResults && c.englishLevel)
  .filter(c =>
    ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission)
  );
  return (
    admitted.reduce((acc, c) => acc + calculator(c), 0) / admitted.length
  ).toFixed(2);
};

const calculatePagoAlContado = candidates =>
  candidates.filter(candy =>
      candy.modalidadDePago === MODALIDAD.AL_CONTADO || candy.modalidadDePago === MODALIDAD.AL_CONTADO_NEW
    ).length

    const calculateADMPagoAlContado = candidates =>
      calculatePagoAlContado(calculateALLAdmitted(candidates))

const calculateFinanciacion = candidates =>
  candidates.filter(candy =>
      candy.modalidadDePago === MODALIDAD.FINANCIACION || candy.modalidadDePago === MODALIDAD.FINANCIACION_NEW
    ).length
    
    const calculateADMFinanciacion = candidates =>
      calculateFinanciacion(calculateALLAdmitted(candidates))

const calculateAPlazos = candidates =>
  candidates.filter(candy =>
      candy.modalidadDePago === MODALIDAD.A_PLAZOS
      || candy.modalidadDePago2 === MODALIDAD.A_PLAZOS )
    .length
    
    const calculateADMAPlazos = candidates =>
      calculateAPlazos(calculateALLAdmitted(candidates))

const calculateAllWithModalidad = candidates => 
  candidates.filter(candy => candy.modalidadDePago)
    .length

  const calculateAllADMWithModalidad = candidates =>
    calculateAllWithModalidad(calculateALLAdmitted(candidates))

const calculateSemipresencial = cs => cs.filter(c => c.preferredSchedule === ADMISSION.SEMIPRESENCIAL).length
const calculateOnline = cs => cs.filter(c => c.preferredSchedule === ADMISSION.ONLINE).length

const calculateADMSemipresencial = cs => calculateSemipresencial(calculateALLAdmitted(cs))
const calculateADMOnline = cs => calculateOnline(calculateALLAdmitted(cs))


const calculateAllWithSchedule = candidates => 
  candidates.filter(candy => candy.preferredSchedule)
    .length
  
  const calculateAllADMWithSchedule = cs => calculateAllWithSchedule(calculateALLAdmitted(cs))


export default {
  twoDigitsPercentage,
  incrementPercentage,
  calculateTotal,
  calculateTotalRegistered,
  calculateOKRegistered,
  calculateNotOKRegistered,
  calculateTotalBetweenPromos,
  calculateTotalInCurrentPromo,
  calculateRatioBetweenPromos,
  calculateRatioInCurrentPromo,
  calculateActive,
  calculateOldActive,
  calculateBetweenProcessesActive,
  calculateNewActive,
  calculateOKActive,
  calculateOKActiveNotStarted,
  calculateOKActiveStarted,
  calculateOKActiveOnlineTests,
  calculateOKActiveF2FTests,
  calculateOKActiveInterview,
  calculateTotalOld,
  calculateOK,
  calculateNotOK,
  calculateProcessCompleted,
  calculateProcessCompletedWithInterview,
  calculateNonAdmitted,
  calculateNonAdmittedOnlineTests,
  calculateNonAdmittedF2FTests,
  calculateNonAdmittedAfterInterview,
  calculateAdmitted,
  calculateAdmittedNotConfirmed,
  calculateAdmittedConfirmed,
  calculateAdmittedRejected,
  calculateAdmittedNotRejected,
  calculateWaitingList,
  calculateAdmittedWithNoHigherStudies,
  calculateAdmittedWithNoPrevKnowledge,
  calculateAdmittedOlderThan34,
  calculateAdmittedWithEnglishLevelA,
  calculateAdmittedWithBATOf2,
  calculateAdmittedWithPrevKnowledge,
  calculateAverageAgeOfAdmitted: calculateAverageOfAdmitted(ageCalculator),
  calculateAverageProfileOfAdmitted: calculateAverageOfAdmitted(
    processor.calculateProfileScore
  ),
  calculateAverageBATOfAdmitted: calculateAverageOfAdmitted(batCalculator),
  calculateAverageInterviewOfAdmitted: calculateAverageOfAdmitted(
    interviewCalculator
  ),
  calculateAverageTotalPointsOfAdmitted: calculateAverageOfAdmitted(
    processor.calculateFinalScore
  ),
  calculatePagoAlContado,
  calculateAllWithModalidad,
  calculateFinanciacion,
  calculateAPlazos,
  calculateSemipresencial,
  calculateOnline,
  calculateAllWithSchedule,
  calculateADMPagoAlContado,
  calculateADMFinanciacion,
  calculateADMAPlazos,
  calculateAllADMWithModalidad,
  calculateADMSemipresencial,
  calculateADMOnline,
  calculateAllADMWithSchedule,
};
