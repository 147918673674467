export const ADMITTED = 'Admitida';
export const ADMITTED_CONFIRMED = 'Admitida confirmada';
export const ADMITTED_REJECTED = 'Admitida rechazada';
export const NON_ADMITTED_TEST_ONLINE = 'No Admitida - Test online';
export const NON_ADMITTED_TEST_PROCTORING = 'No Admitida - Proctoring';
export const NON_ADMITTED_TEST_F2F = 'No Admitida - Test presencial';
export const NON_ADMITTED_INTERVIEW = 'No Admitida - Entrevista';
export const WAITING_LIST = 'Lista Espera';

//OLD STATES
const NON_ADMITTED = 'No Admitida';
const NON_ADMITTED_TESTS = 'No Admitida - Tests';
const NON_ADMITTED_RECOVERABLE = 'No Admitida - Recuperable';

export const NO_ADMISSION = 'Sin admisión';

export const MORNING_SCHEDULE = 'Mañana';
export const EVENING_SCHEDULE = 'Tarde';
export const TO_DEFINE_SCHEDULE = 'Turno por definir';

export const PRESENCIAL = 'Presencial';
export const SEMIPRESENCIAL_PRESENCIAL = 'Semipresencial (inicio presencial)';
export const SEMIPRESENCIAL_ONLINE = 'Semipresencial (inicio online)';
export const ONLINE = 'Online';
export const SIN_DEFINIR = 'Por definir';
export const EMPEZAR_PRESENCIAL = 'Empezar presencial';
export const EMPEZAR_ONLINE = 'Empezar online';

export const USE_MY_LAPTOP = 'Usaré mi propio ordenador';
export const USE_ADALAB_LAPTOP = 'Necesitaré un ordenador de Adalab';

export const ALL = [
  ADMITTED,
  ADMITTED_CONFIRMED,
  ADMITTED_REJECTED,
  NON_ADMITTED_TEST_ONLINE,
  NON_ADMITTED_TEST_PROCTORING,
  NON_ADMITTED_INTERVIEW,
  WAITING_LIST,
];

export const ALL_NON_ADMITTED = [
  NON_ADMITTED,
  NON_ADMITTED_TESTS,
  NON_ADMITTED_RECOVERABLE,
  NON_ADMITTED_TEST_ONLINE,
  NON_ADMITTED_TEST_PROCTORING,
  NON_ADMITTED_INTERVIEW,
];

export const ADMITTED_NOT_REJECTED = [ADMITTED, ADMITTED_CONFIRMED];

export const RANKING_FILTER = [
  NO_ADMISSION,
  NON_ADMITTED_INTERVIEW,
  ADMITTED_REJECTED,
  ADMITTED,
  WAITING_LIST,
];

export const SEMIPRESENCIAL = [
  SEMIPRESENCIAL_PRESENCIAL,
  SEMIPRESENCIAL_ONLINE,
];
export const SCHEDULES = [
  SEMIPRESENCIAL_PRESENCIAL,
  SEMIPRESENCIAL_ONLINE,
  ONLINE,
  // SIN_DEFINIR,
];

export const INITIAL_SCHEDULE = [PRESENCIAL, ONLINE];

export const USE_LAPTOP = [USE_MY_LAPTOP, USE_ADALAB_LAPTOP];

export const preAdmissionOptions = [
  'Admitida',
  'No admitida',
  'Lista de espera',
];
