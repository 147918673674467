import {
  Done as PreOKIcon,
  DoneAll as OKIcon,
  ReportProblem as AvailabilityIcon,
  ChildCare as AgeIcon,
  School as StudiesIcon,
  WorkOff as NoWorkPermissionIcon,
  Clear as ClearIcon
} from '@material-ui/icons';

export const NOT_MINIMUM_STUDIES = 'Estudios';
export const NOT_IN_AGE_RANGE = 'Edad';
export const NEED_MORE_INFO = 'Disponibilidad';
export const APPLICATION_OK = 'OK';
export const APPLICATION_PRE_OK = 'Pre-OK';
export const SIN_PERMISO_TRABAJO = 'Sin permiso trabajo';
export const WITH_2_APPLICATIONS = 'Con 2 solicitudes';

export const ALL = [
  APPLICATION_OK,
  APPLICATION_PRE_OK,
  NEED_MORE_INFO,
  // NOT_IN_AGE_RANGE,
  NOT_MINIMUM_STUDIES,
  SIN_PERMISO_TRABAJO,
  WITH_2_APPLICATIONS,
];

export const ICONS = {
  [APPLICATION_OK]: OKIcon,
  [APPLICATION_PRE_OK]: PreOKIcon,
  [NEED_MORE_INFO]: AvailabilityIcon,
  [NOT_IN_AGE_RANGE]: AgeIcon,
  [NOT_MINIMUM_STUDIES]: StudiesIcon,
  [SIN_PERMISO_TRABAJO]: NoWorkPermissionIcon,
  [WITH_2_APPLICATIONS]: ClearIcon,
};
