import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth } from '../../firebase';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const PasswordForgetPage = () =>
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    auth.doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      error,
    } = this.state;

    const isInvalid = email === '';

    return (
      <form align="center">
        <TextField
          label="Email"
          value={this.state.email}
          onChange={event => this.setState(byPropKey('email', event.target.value))}
          placeholder="ines@adalab.es"
        />
        <Button 
          variant="outlined"
          disabled={isInvalid}
          onClick={this.onSubmit}
        >
          Resetear Mi Contraseña
        </Button>
        { error && 
          <Typography variant="body1" gutterBottom>
            {error.message}
          </Typography>
        }
      </form>
    );
  }
}

const PasswordForgetLink = () =>
  <Typography variant="body1" gutterBottom>
    <Link to="/pw-forget">Forgot Password?</Link>
  </Typography>

export default PasswordForgetPage;

export {
  PasswordForgetForm,
  PasswordForgetLink,
};