import React, { useState } from 'react';
import PropTypes from 'prop-types';
import querySearch from 'stringquery';
import * as ADMISSION from '../../constants/admission';
import * as MODALIDAD from '../../constants/modalidad';
import { download } from '../../csv/';


import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FileIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';

import TableOfAdmitted from './TableOfAdmitted';

const Admitted = ({ candidates, ...rest }) => {  
  const [admission, setAdmission] = useState('all');
  const [assignedSchedule, setAssignedSchedule] = useState('all');
  const [modalidadDePago, setModalidadDePago] = useState('all');
  const [modalidadDePagoOld, setModalidadDePagoOld] = useState('OLD_INVALID');
  const [useLaptop, setUseLaptop] = useState('all');
  const [acceptanceOption, setAcceptanceOption] = useState('all');

  React.useEffect(() => {
    if (!rest || !rest.location || !rest.location.search) return
    const search = querySearch(rest.location.search)

    console.log(search)

    if (search.admission) setAdmission(decodeURIComponent(search.admission))
    if (search.assignedSchedule) setAssignedSchedule(decodeURIComponent(search.assignedSchedule))
    if (search.modalidadDePago) { 
      let modalidadOld = 'OLD_INVALID';
      const modalidad = decodeURIComponent(search.modalidadDePago);

      setModalidadDePago(modalidad)

      if (modalidad === MODALIDAD.FINANCIACION_NEW) {
        modalidadOld = MODALIDAD.FINANCIACION;
      }

      if (modalidad === MODALIDAD.AL_CONTADO_NEW) {
        modalidadOld = MODALIDAD.AL_CONTADO;
      }

      setModalidadDePagoOld(modalidadOld);
    }
    if (search.useLaptop) setUseLaptop(decodeURIComponent(search.useLaptop))
    if (search.acceptanceOption) setAcceptanceOption(decodeURIComponent(search.acceptanceOption))
  }, [rest.location])

  const changeAdmission = event => {
    event.preventDefault();
    setAdmission(event.target.value);
  };

  const changeAssignedSchedule = event => {
    event.preventDefault();
    setAssignedSchedule(event.target.value);
  };

  const changeModalidadDePago = event => {
    let modalidadOld = 'OLD_INVALID';
    event.preventDefault();
    setModalidadDePago(event.target.value);

    if(event.target.value === MODALIDAD.FINANCIACION_NEW){
      modalidadOld = MODALIDAD.FINANCIACION;
    }

    if(event.target.value === MODALIDAD.AL_CONTADO_NEW){
      modalidadOld = MODALIDAD.AL_CONTADO;
    }

    setModalidadDePagoOld(modalidadOld);
  };

  const changeUseLaptop = event => {
    event.preventDefault();
    setUseLaptop(event.target.value);
  };

  const changeAcceptanceOption = event => {
    event.preventDefault();
    setAcceptanceOption(event.target.value);
  };

  let acceptanceOptions = candidates
    .filter(c => c.acceptanceOption)
    .map(c => c.acceptanceOption)
  acceptanceOptions = [...new Set(acceptanceOptions)]

  const filteredCandidates = candidates
    .filter(c => c.admission &&
      (admission === 'all' ? ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission) : c.admission === ADMISSION.ADMITTED_CONFIRMED)
    )
    .filter(
      c =>
        assignedSchedule === 'all' ||
        (!c.assignedSchedule && assignedSchedule === 'without') ||
        c.assignedSchedule === assignedSchedule
    ).filter(
      c =>
        modalidadDePago === 'all' ||
        (!c.modalidadDePago && modalidadDePago === 'without') ||
        c.modalidadDePago === modalidadDePago ||
        c.modalidadDePago === modalidadDePagoOld
    ).filter(
      c =>
        useLaptop === 'all' ||
        (!c.useLaptop && useLaptop === 'without') ||
        c.useLaptop === useLaptop
    ).filter(
      c =>
        acceptanceOption === 'all' ? true :
        c.acceptanceOption === acceptanceOption
    )

  return (
    <div>
      <form action="" className="search-form">
        <div>
          <FormControl style={{ marginRight: 16 }}>
            <InputLabel
              shrink
              htmlFor="admission"
              className="select--label"
            >
              Admisión
            </InputLabel>
            <Select
              value={admission}
              onChange={changeAdmission}
              inputProps={{
                name: 'admission',
                id: 'admission',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="all">
                <em>Todas las admitidas</em>
              </MenuItem>
              <MenuItem value={ADMISSION.ADMITTED_CONFIRMED}>
                <em>Admitidas confirmadas</em>
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ marginRight: 16 }}>
            <InputLabel
              shrink
              htmlFor="assignedSchedule"
              className="select--label"
            >
              Modalidad
            </InputLabel>
            <Select
              value={assignedSchedule}
              onChange={changeAssignedSchedule}
              inputProps={{
                name: 'assignedSchedule',
                id: 'assignedSchedule',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="all">
                <em>Todas</em>
              </MenuItem>
              <MenuItem value="without">
                <em>Sin turno asignado</em>
              </MenuItem>

              {
                ADMISSION.SCHEDULES.map(sch => (
                  <MenuItem
                    value={sch}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {sch}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>

          <FormControl style={{ marginRight: 16 }}>
            <InputLabel
              shrink
              htmlFor="modalidadDePago"
              className="select--label"
            >
              Modalidad de pago
            </InputLabel>
            <Select
              value={modalidadDePago}
              onChange={changeModalidadDePago}
              inputProps={{
                name: 'modalidadDePago',
                id: 'modalidadDePago',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="all">
                <em>Todas</em>
              </MenuItem>
              <MenuItem value="without">
                <em>Sin modalidad asignada</em>
              </MenuItem>

              {
                MODALIDAD.TODAS
                  .filter(m => m === MODALIDAD.FINANCIACION_NEW || m === MODALIDAD.AL_CONTADO_NEW)
                  .map(mod => (
                    <MenuItem
                      value={mod}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {mod}
                    </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
          
          <FormControl style={{ marginRight: 16 }}>
            <InputLabel
              shrink
              htmlFor="useLaptop"
              className="select--label"
            >
              Portátil
            </InputLabel>
            <Select
              value={useLaptop}
              onChange={changeUseLaptop}
              inputProps={{
                name: 'useLaptop',
                id: 'useLaptop',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="all">
                <em>Todas</em>
              </MenuItem>

              {
                ADMISSION.USE_LAPTOP.map(mod => (
                  <MenuItem
                    value={mod}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {mod}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          
          <FormControl style={{ marginRight: 16 }}>
            <InputLabel
              shrink
              htmlFor="useLaptop"
              className="select--label"
            >
              Aceptación de plaza
            </InputLabel>
            <Select
              value={acceptanceOption}
              onChange={changeAcceptanceOption}
              inputProps={{
                name: 'acceptanceOption',
                id: 'acceptanceOption',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="all">
                <em>Todas</em>
              </MenuItem>

              {
                acceptanceOptions.map(mod => (
                  <MenuItem
                    value={mod}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {mod}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>

        <IconButton
          aria-label="Download"
          onClick={download(filteredCandidates, 'admitted')}
        >
          <FileIcon />
        </IconButton>
      </form>
      {filteredCandidates && filteredCandidates.length > 0 && (
        <TableOfAdmitted candidates={filteredCandidates} />
      )}
    </div>
  );
};

Admitted.propTypes = {
  candidates: PropTypes.arrayOf(PropTypes.object),
};

export default Admitted;
