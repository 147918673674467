import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import Navigation from './Navigation';
import SignInPage from './account/SignIn';
import HomePage from './Home';
import AccountPage from './account/Account';

import * as routes from '../constants/routes';
import withAuthentication from './auth/withAuthentication';

const App = () =>
    <div>
      <header>
        <Navigation />
      </header>
      <main>
        <Switch>
          <Route exact path={routes.SIGN_IN} component={SignInPage} />
          <Route exact path={routes.ACCOUNT} component={AccountPage} />
          <Route path={routes.HOME} component={HomePage} />
        </Switch>
      </main>
    </div>

export default withAuthentication(App);