import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

import processor from '../../process';
import * as Interview from '../../process/interview';
import * as colors from '../../constants/colors';

const showFilterResults = result => (
  <Chip
    style={{
      background:
        result === null
          ? colors.success
          : result.includes('WARNING')
          ? colors.warning
          : colors.error,
    }}
    label={result === null ? 'OK' : result}
  />
);

const TestResults = candidate => {
  const {
    age,
    studies,
    studiesDescription,
    prevKnowledge,
    htmlScore,
    jsScore,
    englishScore,
    englishLevel,
    login,
    password,
    teaResults,
    testsDate,
    interviewDate,
    interviewScore,
    interviewFeedback,
    preferredScheduleAfterInterview,
    personality,
    preAdmission,
    modalidadDePago,
    modalidadDePago2
  } = candidate;

  const { resultsPerBlock, results } = Interview.calculateAllResults(candidate);

  return (
    <div className="container">
      <Grid container  spacing={2}>
        <Grid item xs={12}>
          {interviewScore !== undefined && !!teaResults && (
            <Typography variant="subheading" gutterBottom align="center">
              Puntuación total: {processor.calculateFinalScore(candidate)} / 15
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="title" gutterBottom>
            Perfil
          </Typography>
          {englishScore && (
            <Typography variant="subheading" gutterBottom>
              Puntuación perfil: {processor.calculateProfileScore(candidate)} /
              5
            </Typography>
          )}
          <List>
            {
              modalidadDePago && (
                <ListItem>
                  <ListItemText primary="Modalidad de pago" secondary={modalidadDePago} />
                </ListItem>
              )
            }
            
            {
              modalidadDePago2 && (
                <ListItem>
                  <ListItemText primary="Modalidad de pago alternativa" secondary={modalidadDePago2} />
                </ListItem>
              )
            }

            <ListItem>
              <ListItemText primary="Edad" secondary={age} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Estudios"
                secondary={`${studies}: ${studiesDescription}`}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Conocimientos previos"
                secondary={prevKnowledge}
              />
            </ListItem>

            <ListItem>
              <ListItemText primary="Nota Inglés" secondary={englishScore} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Nivel de Inglés"
                secondary={englishLevel}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="title" gutterBottom>
            Tests
          </Typography>
          {!!teaResults && (
            <React.Fragment>
              <Typography variant="subheading" gutterBottom>
                Puntuación tests: {processor.calculateTestsScore(candidate)} / 4
              </Typography>
              <Typography variant="subheading" gutterBottom>
                Filtro 2:
                {showFilterResults(processor.calculateFilter2(candidate))}
              </Typography>
              <Grid container  spacing={4}>
                <Grid item xs={6}>
                  <Typography variant="subheading" gutterBottom>
                    BAT-7: {processor.caculateMarkForBAT7(teaResults)}
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Aptitud numérica"
                        secondary={`${teaResults.BAT7['Aptitud numérica']} ${
                          teaResults.BAT7['Aptitud numérica - Nivel']
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Aptitud verbal"
                        secondary={`${teaResults.BAT7['Aptitud verbal']} ${
                          teaResults.BAT7['Aptitud verbal - Nivel']
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Atención"
                        secondary={`${teaResults.BAT7['Atención']} ${
                          teaResults.BAT7['Atención - Nivel']
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Concentración"
                        secondary={`${teaResults.BAT7['Concentración']} ${
                          teaResults.BAT7['Concentración - Nivel']
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Razonamiento"
                        secondary={`${teaResults.BAT7['Razonamiento']} ${
                          teaResults.BAT7['Razonamiento - Nivel']
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Capacidad general"
                        secondary={`${teaResults.BAT7['Capacidad general']} ${
                          teaResults.BAT7['Capacidad general - Nivel']
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Inteligencia fluida"
                        secondary={`${teaResults.BAT7['Inteligencia fluida']} ${
                          teaResults.BAT7['Inteligencia fluida - Nivel']
                        }`}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {login && (
            <Typography variant="subheading" gutterBottom>
              Usuario: {login}
              <br />
              Password: {password}
            </Typography>
          )}
          <List>
            <ListItem>
              <ListItemText primary="Nota HTML" secondary={htmlScore} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Nota JS" secondary={jsScore} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Fecha de tests"
                secondary={
                  testsDate && new Date(testsDate).toLocaleString('es-ES')
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="title" gutterBottom>
            Entrevista
          </Typography>
          {interviewScore && (
            <Typography variant="subheading" gutterBottom>
              Puntuación entrevista: {interviewScore} / 6
            </Typography>
          )}
          {interviewScore !== undefined &&
            Interview.interviewQuestions.map(block => (
              <React.Fragment key={block.title}>
                <Typography variant="body1">{block.title}</Typography>
                <Chip
                  style={{
                    background: block.validation(
                      results[block.number - 1],
                      resultsPerBlock[block.number - 1]
                    ),
                  }}
                  label={`Resultado: ${results[block.number - 1]}`}
                />
              </React.Fragment>
            ))}
          <List>
            <ListItem>
              <ListItemText primary="Pre-admisión" secondary={preAdmission} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Preferencia de horario en la entrevista"
                secondary={preferredScheduleAfterInterview}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Personalidad de la candidata"
                secondary={personality}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Fecha y hora"
                secondary={
                  interviewDate &&
                  new Date(interviewDate).toLocaleString('es-ES')
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Comentarios entrevista"
                secondary={interviewFeedback}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default TestResults;
