const D = myDate => Date.parse(myDate);
export default {
  current_promo: 'u',
  allPromos: [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ],
  prevPromo() {
    return String.fromCharCode(this.current_promo.charCodeAt(0) - 1);
  },
  promos: {
    a: 'Ada',
    b: 'Borg',
    c: 'Clarke',
    d: 'Dorcas',
    e: 'Easley',
    f: 'Fairfax',
    g: 'Grace',
    h: 'Hamilton',
    i: 'Idelisa',
    j: 'Jemison',
    k: 'Kizzy',
    l: 'Lee',
    m: 'Mileva',
    n: 'Nerea',
    o: 'Olguin',
    p: 'Paz',
    q: 'Quirós',
    r: 'Radia',
    s: 'Salas',
    t: 'NoNamePromoR',
  },
  old_promos: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k'],
  no_data_promos: ['a', 'b', 'c', 'd'],
  data_promos() {
    return Object.keys(this.promos)
      .filter(promo => !this.no_data_promos.includes(promo))
      .reverse();
  },
  isNuPromo(promo) {
    return !this.old_promos.includes(promo);
  },
  promoDates: {
    u: {
      endOfPrevious: D('2023-3-25'),
      start: D('2023-4-4'),
      end: D('2023-8-8'),
    },
    t: {
      endOfPrevious: D('2023-1-4'),
      start: D('2023-1-4'),
      end: D('2023-4-25'),
    },
    s: {
      endOfPrevious: D('2022-9-22'),
      start: D('2022-9-22'),
      end: D('2023-1-4'),
    },
    r: {
      endOfPrevious: D('2022-5-20'),
      start: D('2022-9-22'),
      end: D('2022-5-21'),
    },
    q: {
      endOfPrevious: D('2022-2-7'),
      start: D('2022-2-7'),
      end: D('2022-5-20'),
    },
    p: {
      endOfPrevious: D('2021-10-20'),
      start: D('2021-10-20'),
      end: D('2022-2-7'),
    },
    o: {
      endOfPrevious: D('2021-06-24'),
      start: D('2021-07-1'),
      end: D('2021-10-20'),
    },
    n: {
      endOfPrevious: D('2021-03-18'),
      start: D('2021-03-24'),
      end: D('2021-06-24'),
    },
    m: {
      endOfPrevious: D('2020-11-30'),
      start: D('2020-12-01'),
      end: D('2021-03-18'),
    },
    l: {
      endOfPrevious: D('2020-08-21'),
      start: D('2020-09-14'),
      end: D('2020-11-30'),
    },
    k: {
      endOfPrevious: D('2020-05-05'),
      start: D('2020-06-15'),
      end: D('2020-08-21'),
    },
    j: {
      endOfPrevious: D('2020-01-18'),
      start: D('2020-03-21'),
      end: D('2020-05-05'),
    },
    i: {
      endOfPrevious: D('2019-10-01'),
      start: D('2019-12-02'),
      end: D('2020-01-22'),
    },
    h: {
      endOfPrevious: D('2019-06-07'),
      start: D('2019-09-02'),
      end: D('2019-09-30'),
    },
    g: {
      endOfPrevious: D('2019-02-22'),
      start: D('2019-04-22'),
      end: D('2019-06-06'),
    },
    f: {
      endOfPrevious: D('2018-10-17'),
      start: D('2019-01-09'),
      end: D('2019-02-21'),
    },
    e: {
      endOfPrevious: D('2018-05-18'),
      start: D('2018-09-03'),
      end: D('2018-10-16'),
    },
  },
};
