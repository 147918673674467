import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import * as routes from '../../constants/routes';

import config from '../../config'

import * as PROGRESS from '../../constants/progress';
import * as ADMISSION from '../../constants/admission';
import * as KNOWLEDGE from '../../constants/prevKnowledge';

import processor from '../../process';
import { download } from '../../csv/';

import { prev } from '../../helpers'

import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { Paper, Select, Chip, FormControl, FormControlLabel, Checkbox, Input, InputLabel, Typography, Toolbar, IconButton, MenuItem } from '@material-ui/core';


import FileIcon from '@material-ui/icons/FileCopy';

import './Ranking.css';

const twoDecimalsOf = n => Math.round(n * 100) / 100;

const updatePrevKnowledgeForOldCandidates = candidate => {
  if (candidate.prevKnowledge === 'Sí, he tocado alguna vez HTML y CSS')
    candidate.prevKnowledge = KNOWLEDGE.FEW_HTML_KNOWLEDGE;
  if (
    candidate.prevKnowledge ===
    'Sí, conozco un poco fundamentos de programación (bucles, condicionales)'
  )
    candidate.prevKnowledge = KNOWLEDGE.BASIC_PROGRAMMING_KNOWLEDGE;
  if (candidate.prevKnowledge === 'Sí, soy capaz de crear un script')
    candidate.prevKnowledge = KNOWLEDGE.PROGRAMMING_KNOWLEDGE;
  if (
    candidate.prevKnowledge === 'Sí, soy capaz de hacer una web con HTML y CSS'
  )
    candidate.prevKnowledge = KNOWLEDGE.WEB_KNOWLEDGE;
  return candidate;
};

const adalabersPromosToCompare = [prev(prev(config.current_promo)), prev(config.current_promo)]

const processOldCandidates = oldCandidates =>
  oldCandidates
    .filter(c => adalabersPromosToCompare.includes(c.promo))
    .filter(c => c.admission === ADMISSION.ADMITTED_CONFIRMED)
    .map(c => updatePrevKnowledgeForOldCandidates(c))
    .map(adalaber => {
      if(adalaber.promo === 'i') {
        console.log(adalaber.email)
      }
      const testScore = processor.calculateTestsScore(adalaber);
      const profileScore = processor.calculateProfileScore(adalaber);
      return {
        ...adalaber,
        progress: undefined,
        insertionStatus: adalaber.insertionStatus === false ? false : true,
        abandoned: adalaber.abandoned || false,
        // admission: `Adalaber ${adalaber.promo === 'g' ? 'Grace' : 'Fairfax'}`,
        admission: `Adalaber ${config.promos[adalaber.promo]}`,
        testScore,
        interviewScore: adalaber.interviewScore,
        profileScore,
        finalScore: twoDecimalsOf(
          profileScore + testScore + adalaber.interviewScore
        ),
      };
    });

const addDataToCandidate = candidate => {
  const { teaResults, interviewScore } = candidate;
  return {
    ...candidate,
    profileScore: processor.calculateProfileScore(candidate),
    testScore: (teaResults && processor.calculateTestsScore(candidate)) || 0,
    interviewScore: interviewScore || 0,
    finalScore:
      (interviewScore && processor.calculateFinalScore(candidate)) || 0,
  };
};

const processCandidates = (
  candidates,
  old,
  currentCandidateId,
  showAdalabers,
  showAdmission
) => {
  const processCompletedCandidates = candidates
    .filter(
      candidate =>
        !!candidate.teaResults &&
        ((candidate.progress === PROGRESS.PROCESS_COMPLETED &&
          candidate.admission !== ADMISSION.NON_ADMITTED_TEST_F2F) ||
          candidate.progress === PROGRESS.INTERVIEW_COMPLETED)
    )
    .filter(candy => candy.englishScore)
    .filter(candidate => {
      if (!candidate.admission) return showAdmission[ADMISSION.NO_ADMISSION];
      const candidateAdmission = Object.keys(showAdmission).find(admission =>
        candidate.admission.startsWith(admission)
      );

      return showAdmission[candidateAdmission];
    })
    .map(addDataToCandidate);

  const currentCandidate =
    candidates.find(c => c.id === currentCandidateId) || null;

  if (currentCandidate) {
    if (!processCompletedCandidates.find(c => c.id === currentCandidate.id)) {
      processCompletedCandidates.push(currentCandidate);
    }
  }

  return [
    ...processCompletedCandidates,
    ...(showAdalabers ? processOldCandidates(old) : []),
  ];
};

const orderNow = (candidates, orderBy) =>
  candidates.sort((a, b) => b[orderBy] - a[orderBy]);

const Ranking = ({ candidates, old, id, history }) => {
  // const classes = useStyles();
  // const theme = useTheme();


  const [orderBy, setOrderBy] = useState('finalScore');
  const [showAdalabers, setShowAdalabers] = useState(true);
  const showAdmission = {}, setAdmission = {};


  ADMISSION.RANKING_FILTER.forEach(admission => {
    [showAdmission[admission], setAdmission[admission]] = useState(true);
  });


  const [admissionSelect, setAdmissionSelect] = useState(Object.keys(showAdmission))

  const changeOrder = field => event => {
    event.preventDefault();
    setOrderBy(field);
  };

  const orderedCandidates = orderNow(
    processCandidates(candidates, old, id, showAdalabers, showAdmission),
    orderBy
  );

  const handleChange = event => {
    const options = event.target.value

    const changing = (admissionSelect.length > options.length)
          ? admissionSelect.filter(el => !options.includes(el))
          : options.filter(el => !admissionSelect.includes(el))

    setAdmission[changing](!showAdmission[changing])

    setAdmissionSelect(options)
  };

  return (
    !!candidates && (
      <div className="">
        <Toolbar style={{ padding: 0 }}>
          <form action="" className="search-form">
            <div>
              <FormControl variant="outlined">
                <InputLabel id="options-label">Opciones</InputLabel>
                <Select
                  // labelId="options-label"
                  id="options"
                  multiple
                  variant="outlined"
                  value={admissionSelect}
                  onChange={handleChange}
                  input={<Input id="options-chip" />}
                  style={{ cursor: 'pointer' }}
                  
                  renderValue={selected => (
                    <div>
                      {selected.map(value => ( <Chip style={{marginRight: '6px', background: 'var(--adalabBlueTrans)'}} key={value} label={value} /> ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {ADMISSION.RANKING_FILTER.map(admission =>
                    (
                      <MenuItem key={admission} value={admission}>
                        {admission}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={showAdalabers}
                  onChange={() => setShowAdalabers(!showAdalabers)}
                  value="checked"
                />
              }
              label="Mostrar adalabers"
            />
          </form>
        </Toolbar>
        <RankingTable
          orderBy={orderBy}
          candidates={orderedCandidates}
          currentCandidateID={id}
          changeOrder={changeOrder}
          history={history}
        />
      </div>
    )
  );
};

Ranking.propTypes = {
  candidates: PropTypes.array.isRequired,
  old: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  id: PropTypes.string,
};

const RankingTable = ({
  candidates,
  changeOrder,
  currentCandidateID,
  orderBy,
  history,
}) => {
  const calculateOnclickAndClass = (id, progress, insertionStatus, abandoned) => {
    if (!progress)
      return {
        className: `active ${insertionStatus ? 'inserted' : 'not-inserted'} ${abandoned && 'abandoned'}`,
        onClick: () => history.push(`${routes.OLD}/${id}`),
      };
    return {
      className: currentCandidateID === id ? 'current active' : 'active',
      onClick: () => history.push(`${routes.CANDIDATES}/${id}`),
      hover: true,
    };
  };

  return (
    <Paper>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography color="secondary" variant="title">
          {candidates.length} Resultados
        </Typography>
        <IconButton
          aria-label="Download"
          color="primary"
          onClick={download(candidates, 'ranking', [
            'promo',
            'admission',
            'firstName',
            'lastName',
            'profileScore',
            'testScore',
            'interviewScore',
            'finalScore',
          ])}
        >
          <FileIcon />
        </IconButton>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tipo</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>
              <OrderLink
                changeOrder={changeOrder}
                name="Nota Perfil"
                field="profileScore"
                orderBy={orderBy}
              />
            </TableCell>
            <TableCell>
              <OrderLink
                changeOrder={changeOrder}
                name="Nota Tests"
                field="testScore"
                orderBy={orderBy}
              />
            </TableCell>
            <TableCell>
              <OrderLink
                changeOrder={changeOrder}
                name="Nota Entrevista"
                field="interviewScore"
                orderBy={orderBy}
              />
            </TableCell>
            <TableCell>
              <OrderLink
                changeOrder={changeOrder}
                name="Nota Total"
                field="finalScore"
                orderBy={orderBy}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {candidates.map(
            ({
              id,
              progress,
              admission,
              preAdmission,
              firstName,
              lastName,
              insertionStatus,
              abandoned,
              profileScore,
              testScore,
              interviewScore,
              finalScore,
            }) => (
              <TableRow
                key={id}
                {...calculateOnclickAndClass(id, progress, insertionStatus, abandoned)}
              >
                <TableCell>
                  {admission ? admission : `${preAdmission} (pre)`}
                </TableCell>
                <TableCell>
                  {firstName} {lastName}
                </TableCell>
                <TableCell>{profileScore}</TableCell>
                <TableCell>{testScore}</TableCell>
                <TableCell>{interviewScore}</TableCell>
                <TableCell>{finalScore}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

RankingTable.propTypes = {
  candidates: PropTypes.array.isRequired,
  changeOrder: PropTypes.func.isRequired,
  currentCandidateID: PropTypes.string,
  orderBy: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

const OrderLink = ({ name, field, changeOrder, orderBy }) => (
  <TableSortLabel
    active={orderBy === field}
    direction="desc"
    onClick={changeOrder(field)}
  >
    {name}
  </TableSortLabel>
);

OrderLink.propTypes = {
  name: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default withRouter(Ranking);
