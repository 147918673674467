import React from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

import { db } from '../../firebase';
import { default as mailchimp } from '../../mailchimp';

import * as PROGRESS from '../../constants/progress';
import * as ADMISSION from '../../constants/admission';
import { findOldCandidates } from '../../process/old';

import { Toolbar, IconButton, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';

import PersonalData from './PersonalData';
import TestResults from './TestResults';
import AdmissionData from './AdmissionData';
import UpdateCandidateForm from './UpdateCandidateForm';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.doUpdate = this.doUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      feedback: '',
      tabIndex: 0,
      oldApplications: [],
    };
  }

  async componentDidMount() {
    const { id, old, oldCandidates, nuCandidates } = this.props;
    let candidate;

    if (old) {
      candidate = oldCandidates.find(candy => candy.id === id);
      // console.log('OLD', candidate)
    } else {
      candidate = nuCandidates.find(candy => candy.id === id);
      // console.log('NU', candidate)
    }

    if (!candidate.assignedSchedule && candidate.preferredSchedule) {
      candidate.assignedSchedule = candidate.preferredSchedule;
    }

    this.setState({
      ...candidate,
      referralMarketing: candidate.referralMarketing || candidate.referral,
      referralAltMarketing:
        candidate.referralAltMarketing || candidate.referralAlt,
      oldApplications: oldCandidates
        ? findOldCandidates(oldCandidates, candidate)
        : [],
    });
  }

  handleChange(event) {
    const { name, value, type } = event.target;
    if (type === 'number') {
      this.setState({ [name]: Number(value) });
    } else {
      this.setState({ [name]: value });
      /* if (name === 'assignedSchedule' && value === ADMISSION.ONLINE) {
        this.setState({ modalidadInicial: 'ninguna' })
      } */
    }
  }

  handleTab = (event, value) => this.setState({ tabIndex: value });

  _getFieldOrNothing(f) {
    return this.state[f] !== undefined ? { [f]: this.state[f] } : {};
  }

  _fieldsToUpdate(field, extras, computed = {}) {
    if (field === 'abandoned') {
      console.log('Candidate abandoned course');
      return { abandoned: true };
    }

    /* // No funciona
    if (extras && extras.preferredSchedule && !extras.assignedSchedule) {
      this.setState({ assignedSchedule: extras.preferredSchedule })
      extras.assignedSchedule = extras.preferredSchedule
    } */

    if (!extras) return { [field]: this.state[field] };
    let initialState =
      field === 'progress'
        ? { progress: PROGRESS.INTERVIEW_COMPLETED }
        : {
            admission: this.state['admission'],
            progress: PROGRESS.PROCESS_COMPLETED,
          };
    if (this.state[field] === ADMISSION.ADMITTED) {
      initialState.admissionLetterSentDate = new Date().toISOString();
    }
    return extras.reduce(
      (acc, f) => ({
        ...acc,
        ...this._getFieldOrNothing(f),
      }),
      {
        ...initialState,
        ...computed,
      }
    );
  }

  _sendChangeToMailchimp(field, extras) {
    if (
      this.state['admission'] === ADMISSION.ADMITTED_CONFIRMED ||
      field === 'modalidadDePago' ||
      field === 'preferredSchedule' ||
      field === 'assignedSchedule'
    )
      return candidate => Promise.resolve(candidate);

    if (!extras) return mailchimp[field];
    return field === 'progress'
      ? mailchimp['progress']
      : mailchimp['admission'];
  }

  doUpdate(field, extras, computed) {
    return event => {
      event.preventDefault();
      this.setState({
        feedback: 'EN PROGRESO',
      });
      db.updateCandidate(
        this.state.id,
        this._fieldsToUpdate(field, extras, computed)
      )
        .then(candidate =>
          this._sendChangeToMailchimp(
            field,
            extras
          )(candidate).then(() => candidate)
        )
        .then(candidate =>
          this.setState({
            ...candidate,
          })
        )
        .then(() => {
          this.setState({
            feedback: 'COMPLETADO',
          });
          setTimeout(
            () =>
              this.setState({
                feedback: '',
              }),
            2000
          );
        });
    };
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      admission,
      teaResults,
      tabIndex,
      oldApplications,
      promo,
    } = this.state;

    const { id, old, history } = this.props;

    return (
      <div className="container">
        <Toolbar
          variant="dense"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            style={{ transform: 'translateY(-2px) scale(.8)' }}
            aria-label="Back"
            onClick={() => history.goBack()}
          >
            <BackIcon />
          </IconButton>
          <Typography variant="subtitle1" gutterBottom align="center">
            {firstName} {lastName} - {email}
          </Typography>

          <div
            style={{
              marginLeft: 'auto',
              opacity: '.7',
              display: 'flex',
            }}
          >
            {!old && (
              <Typography
                variant="body1"
                gutterBottom
                align="center"
                style={{}}
              >
                Promoción: {promo}
              </Typography>
            )}

            {!old && !!teaResults && (
              <Typography
                variant="body1"
                gutterBottom
                align="center"
                style={{ paddingLeft: 16 }}
              >
                <Link to={`${routes.RANKING}/${id}`}> Ver en el Ranking</Link>
              </Typography>
            )}

            {oldApplications.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  gutterBottom
                  align="center"
                  style={{ paddingLeft: 16 }}
                >
                  Solicitudes anteriores
                </Typography>
                {oldApplications
                  .filter(oldie => oldie.promo !== this.state.promo)
                  .map(oldApp => (
                    <Typography
                      variant="body1"
                      gutterBottom
                      align="center"
                      key={oldApp.id}
                      style={{ marginLeft: '.5em' }}
                    >
                      <Link
                        to={`${routes.OLD}/${oldApp.id}`}
                      >{`${oldApp.firstName} ${oldApp.lastName} (promo ${oldApp.promo})`}</Link>
                    </Typography>
                  ))}
              </>
            )}
          </div>
        </Toolbar>

        <Tabs
          onChange={this.handleTab}
          value={this.state.tabIndex}
          variant="fullWidth"
        >
          <Tab label="Modificar candidata" />
          <Tab label="Datos personales" />
          <Tab label="Pruebas" />
          <Tab
            label="Admisión"
            disabled={admission !== ADMISSION.ADMITTED_CONFIRMED}
          />
        </Tabs>
        {tabIndex === 0 && firstName && (
          <UpdateCandidateForm
            {...this.state}
            handleChange={this.handleChange}
            doUpdate={this.doUpdate}
            old={old}
            oldApplications={oldApplications}
          />
        )}
        {tabIndex === 1 && (
          <Paper>
            <PersonalData {...this.state} />
          </Paper>
        )}
        {tabIndex === 2 && (
          <Paper>
            <TestResults {...this.state} />
          </Paper>
        )}
        {tabIndex === 3 && (
          <Paper>
            <AdmissionData {...this.state} />
          </Paper>
        )}
      </div>
    );
  }
}

Detail.propTypes = {
  id: PropTypes.string.isRequired,
  old: PropTypes.bool,
  oldCandidates: PropTypes.array,
  history: PropTypes.object,
};

export default withRouter(Detail);
