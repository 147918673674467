export const NO_PREV_KNOWLEDGE = 'No, ninguno';
export const FEW_HTML_KNOWLEDGE = 'Sí, conozco un poco de HTML y CSS';
export const HTML_KNOWLEDGE = 'Sí, he maquetado varias páginas con HTML y CSS';
export const BASIC_PROGRAMMING_KNOWLEDGE =
  'Sí, conozco un poco los fundamentos de programación como los bucles y las funciones';
export const PROGRAMMING_KNOWLEDGE =
  'Sí, he creado programas con un lenguaje de programación como JavaScript o Python';
export const WEB_KNOWLEDGE =
  'Sí, he creado páginas web interactivas con HTML, CSS y JavaScript';
export const NETWORK_KNOWLEDGE =
  'No, pero tengo conocimientos de sistemas y redes';
export const DESIGN_KNOWLEDGE =
  'No, pero tengo conocimiento de diseño de productos digitales';

export const prevKnowledgeTypes = {
  NO_PREV_KNOWLEDGE,
  FEW_HTML_KNOWLEDGE,
  HTML_KNOWLEDGE,
  BASIC_PROGRAMMING_KNOWLEDGE,
  PROGRAMMING_KNOWLEDGE,
  WEB_KNOWLEDGE,
  NETWORK_KNOWLEDGE,
  DESIGN_KNOWLEDGE,
};

export const lowPrevKnowledge = [NO_PREV_KNOWLEDGE, FEW_HTML_KNOWLEDGE];

export const realPrevKnowledge = [
  HTML_KNOWLEDGE,
  BASIC_PROGRAMMING_KNOWLEDGE,
  PROGRAMMING_KNOWLEDGE,
  WEB_KNOWLEDGE,
];
