import * as ADMISSION from '../constants/admission';
import { generateDynamicLetter } from '../constants/letter';

const DYNAMIC_LETTER =
  'https://hooks.zapier.com/hooks/catch/2965889/oth32bb/';
// const CREATE_LETTER_URL =
//   'https://hooks.zapier.com/hooks/catch/2965889/0vkl0m/';

const UPDATE_STATUS_URL =
  'https://hooks.zapier.com/hooks/catch/2965889/q8gruh/';
const UPDATE_PROGRESS_URL =
  'https://hooks.zapier.com/hooks/catch/2965889/q8qb7n/';
const UPDATE_ADMISSION_URL =
  'https://hooks.zapier.com/hooks/catch/2965889/qx7rou/';

const UPDATE_URL_NEW = (field, isLetter) => {
  return isLetter
    ? DYNAMIC_LETTER
    : {
        status: UPDATE_STATUS_URL,
        progress: UPDATE_PROGRESS_URL,
        admission: UPDATE_ADMISSION_URL,
      }[field];
}

const updateNew = field => candidate => {
  console.log('CANDY', candidate)
  const letterField =
    candidate[field] === ADMISSION.ADMITTED
      ? generateDynamicLetter(
          candidate.firstName,
          candidate.modalidadDePago,
          candidate.assignedSchedule,
          candidate.daysToAccept,
        )
      : {};

  console.log('Letter vars', letterField)
  //Send NON_ADMITTED_INTERVIEW email to candidates that are forced non admitted
  const value =
    candidate[field] === ADMISSION.NON_ADMITTED_TEST_F2F
      ? ADMISSION.NON_ADMITTED_INTERVIEW
      : candidate[field];

  return fetch(UPDATE_URL_NEW(field, value === ADMISSION.ADMITTED), {
    method: 'POST',
    body: JSON.stringify({
      email: candidate.email,
      [field]: value,
      ...letterField,
    }),
  });
};

export default {
  progress: updateNew('progress'),
  status: updateNew('status'),
  admission: updateNew('admission'),
};
