import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as routes from '../../constants/routes';

import * as STATUS from '../../constants/status';
import { getTheDate } from '../../helpers';
// import * as PROGRESS from '../../constants/progress';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

const getStatusIconWithTooltip = status => (
  <Tooltip title={status}>{React.createElement(STATUS.ICONS[status])}</Tooltip>
);
// const getProgressIconWithTooltip = progress => <Tooltip title={progress}>{React.createElement(PROGRESS.ICONS[progress])}</Tooltip>;

const getDaysSinceDate = first =>
  (Date.now() - Date.parse(first)) / (1000 * 60 * 60 * 24);

const CandidateTable = ({ candidates, history, old }) => (
  <Table className="table">
    <TableHead>
      <TableRow>
        <TableCell>Estado</TableCell>
        <TableCell>Progreso</TableCell>
        <TableCell>Nombre</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Promoción</TableCell>
        <TableCell>Hora tests presenciales</TableCell>
        <TableCell>Hora entrevista</TableCell>
        <TableCell>inscriptionDate</TableCell>
        <TableCell>creationTimestamp</TableCell>
        {/* <TableCell>Fecha de última modificación</TableCell> */}
      </TableRow>
    </TableHead>
    <TableBody>
      {candidates.map(
        ({
          id,
          status,
          progress,
          firstName,
          lastName,
          email,
          promo,
          interviewDate,
          testsDate,
          inscriptionDate,
          creationTimestamp,
        }) => {
          return (
            <TableRow
              hover
              key={id}
              onClick={() =>
                history.push(`${old ? routes.OLD : routes.CANDIDATES}/${id}`)
              }
              style={
                getDaysSinceDate(inscriptionDate) > 7
                  ? { backgroundColor: '#f4cccb' }
                  : {}
              }
            >
              <TableCell>{getStatusIconWithTooltip(status)}</TableCell>
              <TableCell>
                {// progress && getProgressIconWithTooltip(progress)
                progress && progress}
              </TableCell>
              <TableCell>
                {firstName} {lastName}
              </TableCell>
              <TableCell>{email}</TableCell>
              <TableCell style={{ textTransform: 'uppercase' }}>
                {promo}
              </TableCell>
              <TableCell>
                {testsDate && new Date(testsDate).toLocaleString('es-ES')}
              </TableCell>
              <TableCell>
                {interviewDate &&
                  new Date(interviewDate).toLocaleString('es-ES')}
              </TableCell>
              <TableCell>
                {inscriptionDate &&
                  getTheDate(inscriptionDate).toLocaleString('es-ES')}
              </TableCell>
              <TableCell>
                {creationTimestamp &&
                  getTheDate(creationTimestamp).toLocaleString('es-ES')}
              </TableCell>
            </TableRow>
          );
        }
      )}
    </TableBody>
  </Table>
);

CandidateTable.propTypes = {
  history: PropTypes.object,
  candidates: PropTypes.array,
  old: PropTypes.array,
};

export default withRouter(CandidateTable);
