import React from 'react';
import PropTypes from 'prop-types';

import InterviewResults from './InterviewResults';

import * as STATUS from '../../constants/status';
import * as PROGRESS from '../../constants/progress';
import * as ADMISSION from '../../constants/admission';
import * as MODALIDAD from '../../constants/modalidad';
import { getTheDate } from '../../helpers';
import config from '../../config/';

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Input,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';

class UpdateCandidateForm extends React.Component {
  render() {
    const {
      status,
      progress,
      admission,
      firstEmailSent,
      firstEmail,
      preferredSchedule,
      modalidadDePago,
      modalidadDePago2,
      daysToAccept,
      interviewDate,
      admissionLetterSentDate,
      acceptanceDate,
      rejectionDate,
      doUpdate,
      handleChange,
      feedback,
      old,
      promo,
    } = this.props;
    const assignedSchedule = this.props.assignedSchedule || preferredSchedule;

    const futurePromos = config.allPromos;
    const indexCurrentPromo = futurePromos.findIndex(
      i => i === config.current_promo
    );
    const indexLastPromo = indexCurrentPromo + 3;

    return (
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs>
            <Card>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  style={{ fontSize: 12, marginBottom: 20 }}
                  color="textSecondary"
                  gutterBottom
                >
                  ESTADO Y PROMO
                </Typography>
                <FormControl>
                  <InputLabel shrink htmlFor="status" className="select--label">
                    Estado
                  </InputLabel>
                  <Select
                    value={status}
                    onChange={handleChange}
                    inputProps={{
                      name: 'status',
                      id: 'status',
                    }}
                    className="select"
                    displayEmpty
                    disabled={old}
                  >
                    <MenuItem value="">
                      <em>Sin Estado</em>
                    </MenuItem>
                    {STATUS.ALL.map(state => (
                      <MenuItem value={state} key={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel shrink htmlFor="promo" className="select--label">
                    Promoción
                  </InputLabel>
                  <Select
                    style={{ textTransform: 'uppercase' }}
                    value={promo}
                    onChange={handleChange}
                    inputProps={{
                      name: 'promo',
                      id: 'promo',
                    }}
                    className="select"
                    displayEmpty
                    disabled={old}
                  >
                    <MenuItem
                      style={{ textTransform: 'uppercase' }}
                      value="por definir"
                    >
                      por definir
                    </MenuItem>

                    {futurePromos
                      .slice(indexCurrentPromo, indexLastPromo)
                      .map(p => (
                        <MenuItem
                          style={{ textTransform: 'uppercase' }}
                          key={p}
                          value={p}
                        >
                          {p}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </CardContent>

              <CardActions>
                <Button
                  onClick={doUpdate('status', ['promo'])}
                  disabled={old}
                  size="small"
                >
                  Actualizar Estado y/o Promo
                </Button>
              </CardActions>
            </Card>

            <br />
            <Button variant="outlined" onClick={doUpdate('abandoned')}>
              Candidata abandonó el curso
            </Button>
          </Grid>
          <Grid item xs>
            <Card>
              <CardContent>
                <Typography
                  style={{ fontSize: 12, marginBottom: 20 }}
                  color="textSecondary"
                  gutterBottom
                >
                  PROGRESO: {progress || 'SIN COMENZAR'}
                </Typography>
                {!progress && firstEmailSent && (
                  <Typography
                    style={{ fontSize: 12, marginBottom: 20, marginTop: -20 }}
                    color="textSecondary"
                    gutterBottom
                  >
                    Primer email enviado
                    {firstEmail &&
                      `: ${getTheDate(firstEmail.MessageDate).toLocaleString(
                        'es-ES'
                      )}`}
                  </Typography>
                )}
                <FormControl>
                  <Select
                    value={progress || ''}
                    onChange={handleChange}
                    inputProps={{
                      name: 'progress',
                      id: 'progress',
                    }}
                    className="select"
                    displayEmpty
                    disabled={old}
                  >
                    <MenuItem value="">
                      <em>Sin empezar</em>
                    </MenuItem>
                    {PROGRESS.ALL.map(p => (
                      <MenuItem value={p} key={p}>
                        {p}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
              <CardActions>
                <Button onClick={doUpdate('progress')} disabled={old}>
                  Actualizar Progreso
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* {
            (admission === ADMISSION.ADMITTED || admission === ADMISSION.ADMITTED_CONFIRMED) && (
              // Modalidad de pago iba aquí.
            )
          } */}

          {(progress === PROGRESS.PROCESS_COMPLETED ||
            progress === PROGRESS.INTERVIEW_COMPLETED) && (
            <>
              <Grid item xs>
                <Card>
                  <CardContent>
                    <Typography style={{ fontSize: 12 }} color="textSecondary">
                      <span>MODALIDAD DE PAGO</span>
                    </Typography>

                    <Typography
                      style={{ fontSize: 10 }}
                      color="textSecondary"
                      gutterBottom={!modalidadDePago2}
                    >
                      <span>Modificar antes de enviar carta de admisión</span>
                    </Typography>

                    {modalidadDePago2 && (
                      <Typography
                        style={{ fontSize: 12, marginBottom: 20 }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Secundaria: {modalidadDePago2}
                      </Typography>
                    )}

                    <FormControl>
                      <Select
                        value={modalidadDePago || ''}
                        onChange={handleChange}
                        inputProps={{
                          name: 'modalidadDePago',
                          id: 'modalidadDePago',
                        }}
                        className="select"
                        displayEmpty
                        disabled={old}
                      >
                        <MenuItem value="">
                          <em>Sin elegir</em>
                        </MenuItem>
                        {[
                          ...new Set([
                            ...MODALIDAD.TODAS.filter(
                              m =>
                                m === MODALIDAD.FINANCIACION_NEW ||
                                m === MODALIDAD.AL_CONTADO_NEW
                            ),
                            modalidadDePago,
                          ]),
                        ].map(p => (
                          <MenuItem value={p} key={p}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={doUpdate('modalidadDePago')}
                      disabled={old}
                    >
                      Actualizar modalidad de pago
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs>
                <Card>
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography
                      style={{ fontSize: 12, marginBottom: 20 }}
                      color="textSecondary"
                      gutterBottom
                    >
                      ADMISIÓN{': '}
                      {admissionLetterSentDate &&
                        new Date(admissionLetterSentDate).toLocaleString(
                          'es-ES'
                        )}
                    </Typography>

                    <>
                      <FormControl>
                        <InputLabel
                          shrink
                          htmlFor="admission"
                          className="select--label"
                        >
                          Admisión
                        </InputLabel>
                        <Select
                          value={admission || ''}
                          onChange={handleChange}
                          inputProps={{
                            name: 'admission',
                            id: 'admission',
                          }}
                          className="select"
                          displayEmpty
                          disabled={old}
                        >
                          <MenuItem value="">
                            <em>Sin valor</em>
                          </MenuItem>
                          {ADMISSION.ALL.map(a => (
                            <MenuItem value={a} key={a}>
                              {a}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {admission && admission === ADMISSION.ADMITTED && (
                        <>
                          <FormControl>
                            <InputLabel
                              shrink
                              htmlFor="assignedSchedule"
                              className="select--label"
                            >
                              Modalidad
                            </InputLabel>
                            <Select
                              value={assignedSchedule}
                              onChange={handleChange}
                              inputProps={{
                                name: 'assignedSchedule',
                                id: 'assignedSchedule',
                              }}
                              className="select"
                              displayEmpty
                              disabled={old}
                            >
                              {ADMISSION.SCHEDULES.map(schedule => (
                                <MenuItem key={schedule} value={schedule}>
                                  {schedule}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl>
                            <InputLabel htmlFor="daysToAccept">
                              Días para aceptar
                            </InputLabel>
                            <Input
                              id="daysToAccept"
                              name="daysToAccept"
                              type="number"
                              value={daysToAccept || 10}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </>
                      )}

                      {admission && admission === ADMISSION.ADMITTED_CONFIRMED && (
                        <>
                          <FormControl
                            style={{
                              display: 'flex',
                            }}
                          >
                            <InputLabel
                              shrink
                              htmlFor="assignedSchedule"
                              className="select--label"
                            >
                              Horario definitivo
                            </InputLabel>
                            <Select
                              value={
                                assignedSchedule
                                  ? assignedSchedule
                                  : preferredSchedule
                              }
                              onChange={handleChange}
                              inputProps={{
                                name: 'assignedSchedule',
                                id: 'assignedSchedule',
                              }}
                              className="select"
                              displayEmpty
                              disabled={old}
                            >
                              {ADMISSION.SCHEDULES.map(schedule => (
                                <MenuItem key={schedule} value={schedule}>
                                  {schedule}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={doUpdate('admission', [
                        'modalidadDePago',
                        'assignedSchedule',
                        'daysToAccept',
                      ])}
                      disabled={old}
                    >
                      Actualizar Admisión
                    </Button>
                  </CardActions>
                </Card>

                {acceptanceDate && (
                  <p>
                    Fecha de aceptación de plaza:{' '}
                    {new Date(acceptanceDate).toLocaleString('es-ES')}
                  </p>
                )}
                {rejectionDate && (
                  <p>
                    Fecha de rechazo de plaza:{' '}
                    {new Date(rejectionDate).toLocaleString('es-ES')}
                  </p>
                )}
              </Grid>
            </>
          )}

          {progress === PROGRESS.INTERVIEW && (
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography
                    style={{ fontSize: 12, marginBottom: 20 }}
                    color="textSecondary"
                    gutterBottom
                  >
                    Datos de entrevista
                  </Typography>
                  <InterviewResults {...this.props} />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        <p>{feedback}</p>
      </div>
    );
  }
}

UpdateCandidateForm.propTypes = {
  status: PropTypes.oneOf(STATUS.ALL),
  progress: PropTypes.oneOf(PROGRESS.ALL),
  admission: PropTypes.oneOf(ADMISSION.ALL),
  applyForScholarship: PropTypes.string,
  scholarship: PropTypes.bool,
  scholarshipAmount: PropTypes.number,
  daysToAccept: PropTypes.number,
  assignedSchedule: PropTypes.oneOf(ADMISSION.SCHEDULES),
  interviewDate: PropTypes.string,
  admissionLetterSentDate: PropTypes.string,
  acceptanceDate: PropTypes.string,
  rejectionDate: PropTypes.string,
  doUpdate: PropTypes.func,
  handleChange: PropTypes.func,
  feedback: PropTypes.string,
  old: PropTypes.bool,
  oldApplications: PropTypes.array,
};

export default UpdateCandidateForm;
