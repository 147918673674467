import * as colors from '../constants/colors';

export const interviewQuestions = [
  {
    title: 'Bloque 1: Programa / candidata fit',
    number: 1,
    questions: [
      '¿Dónde has buscado información sobre nuestro programa?, ¿nos podrías decir cuál es la dedicación, el contenido, la duración, y coste del mismo?',
      '¿Cuándo y por qué tomaste la decisión de convertirte en programadora?',
      '¿Sabes algo de programación?, ¿qué lenguajes y con qué profundidad?, ¿cómo lo aprendiste?, ¿cuántas horas le has dedicado a la programación?, ¿podrías describir con tus palabras qué hace una programadora front-end?, ¿has continuado con las pruebas de HTML y JS del proceso de selección?',
    ],
    validation: result =>
      result < 1 ? colors.error : result <= 2 ? colors.warning : colors.success,
  },
  {
    title: 'Bloque 2: Desarrollo profesional',
    number: 2,
    questions: [
      'Resumen experiencia profesional. Indagar en los cambios y en aquello que no ha terminado. Al final preguntar: ¿Acabas todo lo que empiezas?, con ejemplos, independientemente de lo incluido en el cv.',
      'Dime algo que te hayas propuesto conseguir que te haya supuesto un reto y a pesar de encontrarte dificultades, lo hayas logrado',
    ],
    validation: result =>
      result < 1 ? colors.error : result <= 1 ? colors.warning : colors.success,
  },
  {
    title: 'Bloque 3: Adaptabilidad al curso',
    number: 3,
    questions: [
      'Trabajo en equipo: Coméntame un conflicto que hayas tenido trabajando en equipo. Ponme un ejemplo real que te haya sucedido.// Cuéntame una situación en la que has tenido que trabajar con unos compañeros o jefe que no eran de tu agrado.',
      'Estabilidad emocional: Describe una situación donde un aspecto personal haya influido en tu rendimiento profesional/académico//¿Qué aspectos te gustaría mejorar de ti misma?',
      'Resolución de problemas/tolerancia a la presión: Cuéntanos una situación en la que hayas tenido que trabajar bajo presión y cómo lo afrontaste //Cuando no sabes algo que necesitas resolver, ¿qué haces?, ¿cuánto tiempo inviertes en pasar de una opción a otra?',
      'Flexibilidad: Cuéntame la última vez que no estuviste de acuerdo con una decisión de tu jefe. // ¿Cuáles han sido las situaciones de cambio más importantes a las que te has enfrentado? ¿Qué aprendizaje sacaste de ellas?',
      'Tolerancia a la frustración: Ponme un ejemplo de una situación que no salió como tú esperabas y cómo reaccionaste//¿Cuál es el período de tiempo más largo que has invertido para resolver un problema? ¿cuál era el porblema?',
    ],
    validation: (result, partial) =>
      result <= 2 || !partial.every(n => getResponseValue(n) > 0)
        ? colors.error
        : result < 4
        ? colors.warning
        : colors.success,
  },
  {
    title: 'Bloque 4: Entrevista de trabajo e inglés',
    number: 4,
    questions: ['Comunicación durante la entrevista '],
    validation: result =>
      result === 0
        ? colors.error
        : result < 1
        ? colors.warning
        : colors.success,
  },
];

export const questionNames = interviewQuestions.reduce(
  (acc, block) => [
    ...acc,
    ...block.questions.map(
      (q, number) => `interviewB${block.number}Q${number + 1}`
    ),
  ],
  []
);

const getResponseValue = resp => {
  if (resp === 'OK') return 1;
  if (resp === 'Más o menos') return 0.5;
  return 0;
};

const getFinalResult = (results, partialResults) => {
  const isValid = interviewQuestions.reduce(
    (acc, block, index) =>
      acc &&
      block.validation(results[index], partialResults[index]) !== colors.error,
    true
  );
  if (!isValid) return 0;
  return +(
    ((results[0] / 3 + results[2] / 2 + results[3] / 5 + results[4]) * 6) /
    4
  ).toFixed(2);
};

export const calculateAllResults = data => {
  const resultsPerBlock = [1, 2, 3, 4].map(blockNumber =>
    interviewQuestions[blockNumber - 1].questions.map(
      (q, number) => data[`interviewB${blockNumber}Q${number + 1}`]
    )
  );

  const results = resultsPerBlock.map(result =>
    result.reduce((acc, resp) => acc + getResponseValue(resp), 0)
  );

  const finalResult = getFinalResult(results, resultsPerBlock);

  return {
    resultsPerBlock,
    results,
    finalResult,
  };
};
