import React from 'react';

import * as STATUS from '../../constants/status';
import * as PROGRESS from '../../constants/progress';
import * as ADMISSION from '../../constants/admission';
import * as MODALIDAD from '../../constants/modalidad';

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Divider,
  Avatar,
  Chip,
} from '@material-ui/core';
import { Card, CardActions, CardContent, Box } from '@material-ui/core';

import { withRouter, Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import config from '../../config';

const getIcon = status => React.createElement(STATUS.ICONS[status]);
const isEmpty = str => str.length === 0 || !str.trim();

const Summary = ({ candidates, history }) => {
  let acceptanceOptions = candidates
    .filter(c => c.acceptanceOption)
    .map(c => c.acceptanceOption);
  acceptanceOptions = [...new Set(acceptanceOptions)];

  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card style={{ padding: '1em 0' }}>
            <CardContent>
              <Typography variant="subheading">Estado</Typography>
            </CardContent>

            <List dense={true}>
              {STATUS.ALL.map(state => (
                <ListItem
                  key={state}
                  button
                  onClick={() =>
                    history.push({
                      pathname: routes.HOME,
                      search: `?status=${state}`,
                    })
                  }
                >
                  <ListItemAvatar style={{ transform: 'scale(.8)' }}>
                    <Avatar>{getIcon(state)}</Avatar>
                  </ListItemAvatar>

                  <ListItemText primary={state} />

                  <ListItemSecondaryAction>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={
                        candidates.filter(
                          candidate => candidate.status === state
                        ).length
                      }
                      style={{ transform: 'scale(.8)' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
            <CardActions>
              <Typography variant="body2" gutterBottom>
                Candidatas activas total: {candidates.length}
              </Typography>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card style={{ padding: '1em 0' }}>
            <CardContent>
              <Typography variant="subheading" gutterBottom>
                Progreso
              </Typography>
            </CardContent>
            <List dense={false}>
              {PROGRESS.ALL_ACTIVE.map(p => (
                <ListItem
                  key={p}
                  button
                  onClick={() =>
                    history.push({
                      pathname: routes.HOME,
                      search: `?progress=${p}`,
                    })
                  }
                >
                  <ListItemText primary={p} />
                  <ListItemSecondaryAction>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={
                        candidates.filter(candidate => candidate.progress === p)
                          .length
                      }
                      style={{ transform: 'scale(.8)' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
            <CardActions>
              <Typography variant="body2">
                Candidatas con progreso:{' '}
                {candidates.filter(candidate => candidate.progress).length}
              </Typography>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card style={{ padding: '1em 0' }}>
            <CardContent>
              <Typography variant="subheading">
                Admisión (actual promoción)
              </Typography>
            </CardContent>
            <List dense={false}>
              {ADMISSION.ALL.map(admission => (
                <ListItem
                  key={admission}
                  button
                  onClick={() =>
                    history.push({
                      pathname: routes.HOME,
                      search: `?admission=${admission}&promo=${config.current_promo}`,
                    })
                  }
                >
                  <ListItemText primary={admission} />

                  <ListItemSecondaryAction>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={
                        candidates.filter(
                          candidate =>
                            candidate.admission === admission &&
                            candidate.promo === config.current_promo
                        ).length
                      }
                      style={{ transform: 'scale(.8)' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
            <CardActions
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Typography variant="body2" gutterBottom>
                Candidatas con totales con admisión:{' '}
                {
                  candidates.filter(
                    c => c.admission && c.promo === config.current_promo
                  ).length
                }
              </Typography>

              {/* <Typography variant="body2">
                Candidatas con el proceso completado sin admisión:{' '}
                {
                  candidates.filter(
                    c =>
                      c.progress &&
                      (c.progress === PROGRESS.INTERVIEW_COMPLETED ||
                        c.progress === PROGRESS.PROCESS_COMPLETED) &&
                      !c.admission
                  ).length
                }
              </Typography> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card style={{ padding: '1em 0' }}>
            <CardContent>
              <Typography variant="subheading">
                Admisión (promociones futuras)
              </Typography>
            </CardContent>
            <List dense={false}>
              {ADMISSION.ALL.map(admission => (
                <ListItem
                  key={admission}
                  button
                  onClick={() =>
                    history.push({
                      pathname: routes.HOME,
                      search: `?admission=${admission}&promo=futuras`,
                    })
                  }
                >
                  <ListItemText primary={admission} />

                  <ListItemSecondaryAction>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={
                        candidates.filter(
                          candidate =>
                            candidate.admission === admission &&
                            candidate.promo !== config.current_promo &&
                            candidate.promo !== 'por definir'
                        ).length
                      }
                      style={{ transform: 'scale(.8)' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
            <CardActions
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Typography variant="body2" gutterBottom>
                Candidatas con totales con admisión:{' '}
                {
                  candidates.filter(
                    c =>
                      c.admission &&
                      c.promo !== config.current_promo &&
                      c.promo !== 'por definir'
                  ).length
                }
              </Typography>

              {/* <Typography variant="body2">
                Candidatas con el proceso completado sin admisión:{' '}
                {
                  candidates.filter(
                    c =>
                      c.progress &&
                      (c.progress === PROGRESS.INTERVIEW_COMPLETED ||
                        c.progress === PROGRESS.PROCESS_COMPLETED) &&
                      !c.admission
                  ).length
                }
              </Typography> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card style={{ padding: '1em 0' }}>
            <CardContent>
              <Typography variant="subheading">
                Admisión (promoción por definir)
              </Typography>
            </CardContent>
            <List dense={false}>
              {ADMISSION.ALL.map(admission => (
                <ListItem
                  key={admission}
                  button
                  onClick={() =>
                    history.push({
                      pathname: routes.HOME,
                      search: `?admission=${admission}&promo=por%20definir`,
                    })
                  }
                >
                  <ListItemText primary={admission} />

                  <ListItemSecondaryAction>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={
                        candidates.filter(
                          candidate =>
                            candidate.admission === admission &&
                            candidate.promo === 'por definir'
                        ).length
                      }
                      style={{ transform: 'scale(.8)' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
            <CardActions
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Typography variant="body2" gutterBottom>
                Candidatas con totales con admisión:{' '}
                {
                  candidates.filter(
                    c => c.admission && c.promo === 'por definir'
                  ).length
                }
              </Typography>

              {/* <Typography variant="body2">
                Candidatas con el proceso completado sin admisión:{' '}
                {
                  candidates.filter(
                    c =>
                      c.progress &&
                      (c.progress === PROGRESS.INTERVIEW_COMPLETED ||
                        c.progress === PROGRESS.PROCESS_COMPLETED) &&
                      !c.admission
                  ).length
                }
              </Typography> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card style={{ padding: '1em 0', marginBotton: '1em' }}>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subheading" gutterBottom>
                  Admitidas totales
                </Typography>
                <Chip
                  size="small"
                  variant="outlined"
                  label={
                    candidates.filter(c =>
                      ADMISSION.ADMITTED_NOT_REJECTED.includes(c.admission)
                    ).length
                  }
                  style={{ transform: 'scale(.8)' }}
                />
              </Box>
            </CardContent>
            <List dense={true}>
              {ADMISSION.SCHEDULES.map(SCHEDULE => {
                return (
                  <ListItem
                    key={SCHEDULE}
                    button
                    component={Link}
                    to={`${routes.ADMITTED}?assignedSchedule=${SCHEDULE}`}
                  >
                    <ListItemText primary={SCHEDULE} />
                    <ListItemSecondaryAction>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={
                          candidates.filter(
                            c =>
                              ADMISSION.ADMITTED_NOT_REJECTED.includes(
                                c.admission
                              ) &&
                              !isEmpty(c.admission) &&
                              c.assignedSchedule === SCHEDULE
                          ).length
                        }
                        style={{ transform: 'scale(.8)' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Card>
          <Card style={{ padding: '1em 0', marginTop: '1em' }}>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subheading" gutterBottom>
                  Admitidas confirmadas (actual promoción)
                </Typography>
                <Chip
                  size="small"
                  variant="outlined"
                  label={
                    candidates.filter(
                      c =>
                        c.admission === ADMISSION.ADMITTED_CONFIRMED &&
                        c.promo === config.current_promo
                    ).length
                  }
                  style={{ transform: 'scale(.8)' }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subheading" gutterBottom>
                  Admitidas confirmadas (promociones futuras)
                </Typography>
                <Chip
                  size="small"
                  variant="outlined"
                  label={
                    candidates.filter(
                      c =>
                        c.admission === ADMISSION.ADMITTED_CONFIRMED &&
                        c.promo !== config.current_promo &&
                        c.promo !== 'por definir'
                    ).length
                  }
                  style={{ transform: 'scale(.8)' }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subheading" gutterBottom>
                  Admitidas confirmadas (promoción por definir)
                </Typography>
                <Chip
                  size="small"
                  variant="outlined"
                  label={
                    candidates.filter(
                      c =>
                        c.admission === ADMISSION.ADMITTED_CONFIRMED &&
                        c.promo === 'por definir'
                    ).length
                  }
                  style={{ transform: 'scale(.8)' }}
                />
              </Box>
            </CardContent>
            <List dense={true}>
              {ADMISSION.SCHEDULES.map(SCHEDULE => {
                return (
                  <ListItem
                    key={SCHEDULE}
                    button
                    component={Link}
                    to={`${routes.ADMITTED}?assignedSchedule=${SCHEDULE}`}
                  >
                    <ListItemText primary={SCHEDULE} />
                    <ListItemSecondaryAction>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={
                          candidates.filter(
                            c =>
                              c.admission === ADMISSION.ADMITTED_CONFIRMED &&
                              c.assignedSchedule === SCHEDULE
                          ).length
                        }
                        style={{ transform: 'scale(.8)' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <Divider />

            <List dense={true}>
              {MODALIDAD.TODAS.filter(
                m =>
                  m === MODALIDAD.FINANCIACION_NEW ||
                  m === MODALIDAD.AL_CONTADO_NEW
              ).map(m => {
                const MODALIDAD_OLD =
                  m === MODALIDAD.FINANCIACION_NEW
                    ? MODALIDAD.FINANCIACION
                    : MODALIDAD.AL_CONTADO;

                return (
                  <ListItem
                    key={m}
                    button
                    component={Link}
                    to={`${routes.ADMITTED_CONFIRMED}?modalidadDePago=${m}`}
                  >
                    <ListItemText primary={m} />
                    <ListItemSecondaryAction>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={
                          candidates.filter(
                            c =>
                              c.admission === ADMISSION.ADMITTED_CONFIRMED &&
                              (c.modalidadDePago === m ||
                                c.modalidadDePago === MODALIDAD_OLD)
                          ).length
                        }
                        style={{ transform: 'scale(.8)' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <Divider />

            <Divider />
            <List dense={true}>
              {ADMISSION.USE_LAPTOP.map(LAPTOP => {
                return (
                  <ListItem
                    key={LAPTOP}
                    button
                    component={Link}
                    to={`${routes.ADMITTED_CONFIRMED}?useLaptop=${LAPTOP}`}
                  >
                    <ListItemText primary={LAPTOP} />
                    <ListItemSecondaryAction>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={
                          candidates.filter(
                            c =>
                              c.admission === ADMISSION.ADMITTED_CONFIRMED &&
                              c.useLaptop === LAPTOP
                          ).length
                        }
                        style={{ transform: 'scale(.8)' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Summary);
