import React, { useState } from 'react';
import PropTypes from 'prop-types';

import config from '../../config'
import { prev } from '../../helpers'

import { Typography, Tooltip, Table, TableBody, TableCell, TableHead, TableRow, FormControl, Paper, Toolbar } from '@material-ui/core';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import calculator from '../../process/indicators';

import './Indicators.css';

const getIndividualIndicator = (candidates, candidatesOld, dates, datesOld) => (
  title,
  func,
  important = false,
  noIndicator = false
) => ({
  indicator: title,
  value: calculator[func](candidates, dates),
  valueOld: calculator[func](candidatesOld, datesOld),
  ratio: `${calculator.incrementPercentage(
    calculator[func](candidates, dates) /
      calculator[func](candidatesOld, datesOld)
  )}%`,
  important,
  noIndicator
});

const getValueWithTooltip = (
  title1,
  title2,
  func1,
  func2,
  candidates,
  dates,
  users = []
) => (
  <React.Fragment>
    {calculator[func1](candidates, dates, users)}
    <Tooltip title={`${title1} / ${title2}`}>
      <span>{` (${calculator.twoDigitsPercentage(
        calculator[func1](candidates, dates, users) /
          calculator[func2](candidates, dates, users)
      )}%)`}</span>
    </Tooltip>
  </React.Fragment>
);

const getWithRatioIndicator = (candidates, candidatesOld, dates, datesOld) => (
  title1,
  title2,
  func1,
  func2,
  important = false,
) => ({
  indicator: title1,
  value: getValueWithTooltip(title1, title2, func1, func2, candidates, dates),
  valueOld: getValueWithTooltip(
    title1,
    title2,
    func1,
    func2,
    candidatesOld,
    datesOld
  ),
  ratio: `${calculator.incrementPercentage(
    calculator[func1](candidates, dates) /
      calculator[func1](candidatesOld, datesOld)
  )}%`,
  important,
});

const getResultsForTable = (individualIndicator, ratioIndicator) => [
  {
    title: 'ACTIVAS',
    data: [
      individualIndicator('Activas', 'calculateActive', true),
      ratioIndicator(
        'Antiguas',
        'Activas',
        'calculateOldActive',
        'calculateActive'
      ),
      ratioIndicator(
        'Entre procesos',
        'Activas',
        'calculateBetweenProcessesActive',
        'calculateActive'
      ),
      ratioIndicator(
        'Nuevas',
        'Activas',
        'calculateNewActive',
        'calculateActive'
      ),
      ratioIndicator('OK', 'Activas', 'calculateOKActive', 'calculateActive'),
    ],
  },
  {
    title: 'PROGRESO',
    data: [
      individualIndicator('OK', 'calculateOKActive', true),
      ratioIndicator(
        'Proceso no iniciado',
        'OK',
        'calculateOKActiveNotStarted',
        'calculateOKActive'
      ),
      ratioIndicator(
        'Proceso iniciado',
        'OK',
        'calculateOKActiveStarted',
        'calculateOKActive'
      ),
      ratioIndicator(
        'Tests online',
        'OK',
        'calculateOKActiveOnlineTests',
        'calculateOKActive'
      ),
      ratioIndicator(
        'Tests inglés y psicométricos',
        'OK',
        'calculateOKActiveF2FTests',
        'calculateOKActive'
      ),
      ratioIndicator(
        'Entrevista',
        'OK',
        'calculateOKActiveInterview',
        'calculateOKActive'
      ),
      ratioIndicator(
        'Proceso completado',
        'OK',
        'calculateProcessCompleted',
        'calculateOKActive'
      ),
    ],
  },
  {
    title: 'OPCIONES DEL CURSO',
    data: [
      ratioIndicator(
        'Modalidad de pago',
        'OK',
        'calculateAllWithModalidad',
        'calculateAllWithModalidad',
        true,
        true
      ),
      ratioIndicator(
        'Pago al contado',
        'OK',
        'calculatePagoAlContado',
        'calculateAllWithModalidad'
      ),
      ratioIndicator(
        'Quiere solicitar financiación',
        'OK',
        'calculateFinanciacion',
        'calculateAllWithModalidad'
      ),
      // ratioIndicator(
      //   'Pago a plazos',
      //   'OK',
      //   'calculateAPlazos',
      //   'calculateAllWithModalidad'
      // ),
      ratioIndicator(
        'Modalidad',
        'OK',
        'calculateAllWithSchedule',
        'calculateAllWithSchedule',
        true,
        true
      ),
      ratioIndicator(
        'Modalidad: Semipresencial',
        'OK',
        'calculateSemipresencial',
        'calculateOKActive'
      ),
      ratioIndicator(
        'Modalidad: Online',
        'OK',
        'calculateOnline',
        'calculateOKActive'
      ),
    ],
  },
  {
    title: 'ADMISIÓN',
    data: [
      ratioIndicator(
        'Total no admitidas',
        'Proceso completado',
        'calculateNonAdmitted',
        'calculateProcessCompleted',
        true
      ),
      ratioIndicator(
        'No admitidas test online',
        'Proceso completado',
        'calculateNonAdmittedOnlineTests',
        'calculateProcessCompleted'
      ),
      ratioIndicator(
        'No admitidas test presenciales',
        'Proceso completado',
        'calculateNonAdmittedF2FTests',
        'calculateProcessCompleted'
      ),
      ratioIndicator(
        'No admitidas con entrevista',
        'Proceso completado',
        'calculateNonAdmittedAfterInterview',
        'calculateProcessCompleted'
      ),
      ratioIndicator(
        'Total admitidas',
        'Proceso completado',
        'calculateAdmitted',
        'calculateProcessCompleted',
        true
      ),
      ratioIndicator(
        'Admitidas sin confirmar',
        'Total admitidas',
        'calculateAdmittedNotConfirmed',
        'calculateAdmitted'
      ),
      ratioIndicator(
        'Admitidas confirmadas',
        'Total admitidas',
        'calculateAdmittedConfirmed',
        'calculateAdmitted'
      ),
      ratioIndicator(
        'Admitidas rechazadas',
        'Total admitidas',
        'calculateAdmittedRejected',
        'calculateAdmitted'
      ),
    ],
  },
  {
    title: 'ADMITIDAS (Opciones del curso)',
    data: [
      ratioIndicator(
        'Modalidad de pago',
        'OK',
        'calculateAllADMWithModalidad',
        'calculateAllADMWithModalidad',
        true,
        true
      ),
      ratioIndicator(
        'Pago al contado con recursos propios',
        'OK',
        'calculateADMPagoAlContado',
        'calculateAllADMWithModalidad'
      ),
      ratioIndicator(
        'Pago al contado con financiación',
        'OK',
        'calculateADMFinanciacion',
        'calculateAllADMWithModalidad'
      ),
      ratioIndicator(
        'Pago a plazos',
        'OK',
        'calculateADMAPlazos',
        'calculateAllADMWithModalidad'
      ),
      ratioIndicator(
        'Modalidad',
        'OK',
        'calculateAllADMWithSchedule',
        'calculateAllADMWithSchedule',
        true,
        true
      ),
      ratioIndicator(
        'Modalidad: Semipresencial',
        'OK',
        'calculateADMSemipresencial',
        'calculateAllADMWithSchedule'
      ),
      ratioIndicator(
        'Modalidad: Online',
        'OK',
        'calculateADMOnline',
        'calculateAllADMWithSchedule'
      ),
    ],
  },
  {
    title: 'ADMITIDAS (perfil con riesgo)',
    data: [
      ratioIndicator(
        'Sin estudios superiores',
        'Admitidas',
        'calculateAdmittedWithNoHigherStudies',
        'calculateAdmittedNotRejected'
      ),
      ratioIndicator(
        'Sin conocimientos previos',
        'Admitidas',
        'calculateAdmittedWithNoPrevKnowledge',
        'calculateAdmittedNotRejected'
      ),
      ratioIndicator(
        'Mayores de 34',
        'Admitidas',
        'calculateAdmittedOlderThan34',
        'calculateAdmittedNotRejected'
      ),
      ratioIndicator(
        'Nivel de inglés A',
        'Admitidas',
        'calculateAdmittedWithEnglishLevelA',
        'calculateAdmittedNotRejected'
      ),
      ratioIndicator(
        'BAT 2',
        'Admitidas',
        'calculateAdmittedWithBATOf2',
        'calculateAdmittedNotRejected'
      ),
      ratioIndicator(
        'Con conocimientos previos',
        'Admitidas',
        'calculateAdmittedWithPrevKnowledge',
        'calculateAdmittedNotRejected'
      ),
    ],
  },
  {
    title: 'ADMITIDAS (medias)',
    data: [
      individualIndicator('Edad media', 'calculateAverageAgeOfAdmitted'),
      individualIndicator('Perfil medio', 'calculateAverageProfileOfAdmitted'),
      individualIndicator('BAT medio', 'calculateAverageBATOfAdmitted'),
      individualIndicator(
        'Puntuación entrevista media',
        'calculateAverageInterviewOfAdmitted'
      ),
      individualIndicator(
        'Puntuación total media',
        'calculateAverageTotalPointsOfAdmitted'
      ),
    ],
  },
];
const calculateInscription = ({current, last, candidates, candidatesOld, users, current_date, last_date}) => {
  const getRegisteredUnsure = config.isNuPromo(last)
    ? calculator['calculateTotalRegistered'](candidatesOld, last_date, users)
    : calculator['calculateTotalRegistered'](candidatesOld, last_date)

  const getFuncUnsure = func => config.isNuPromo(last)
    ? calculator[func](candidatesOld, last_date, users)
    : calculator[func](candidatesOld, last_date)

  const getRatioUnsure = func => `${calculator.incrementPercentage(
    calculator[func](candidates, current_date, users) /
    getFuncUnsure(func)
  )}%`

  return [
    {
      title: 'INSCRIPCIÓN',
      data: [
        {
          indicator: 'Inscritas',
          value: calculator['calculateTotalRegistered'](candidates, current_date, users),
          valueOld: calculator['calculateTotalRegistered'](candidatesOld, last_date),
          ratio: `${calculator.incrementPercentage(
            calculator['calculateTotalRegistered'](candidates, current_date, users) /
            getRegisteredUnsure
          )}%`,
          important: true
        },
        {
          indicator: 'Entre procesos',
          value: getValueWithTooltip(
            'Entre procesos',
            'Inscritas',
            'calculateTotalBetweenPromos',
            'calculateTotalRegistered',
            candidates,
            current_date,
            users
          ),
          valueOld: getValueWithTooltip(
            'Entre procesos',
            'Inscritas',
            'calculateTotalBetweenPromos',
            'calculateTotalRegistered',
            candidatesOld,
            last_date,
          ),
          ratio: `${calculator.incrementPercentage(
            calculator['calculateTotalBetweenPromos'](candidates, current_date, users) /
            getRegisteredUnsure
          )}%`,
        },
        {
          indicator: 'Inscritas entre procesos por día',
          value: calculator['calculateRatioBetweenPromos'](candidates, current_date, users),
          valueOld: calculator['calculateRatioBetweenPromos'](candidatesOld, last_date),
          ratio: getRatioUnsure('calculateRatioBetweenPromos'),
        },
        {
          indicator: 'Nuevas',
          value: getValueWithTooltip(
            'Nuevas',
            'Inscritas',
            'calculateTotalInCurrentPromo',
            'calculateTotalRegistered',
            candidates,
            current_date,
            users
          ),
          valueOld: getValueWithTooltip(
            'Nuevas',
            'Inscritas',
            'calculateTotalInCurrentPromo',
            'calculateTotalRegistered',
            candidatesOld,
            last_date,
          ),
          ratio: getRatioUnsure('calculateTotalInCurrentPromo'),
        },
        {
          indicator: 'Inscritas en este proceso por día',
          value: calculator['calculateRatioInCurrentPromo'](candidates, current_date, users),
          valueOld: calculator['calculateRatioInCurrentPromo'](candidatesOld, last_date),
          ratio: getRatioUnsure('calculateRatioInCurrentPromo'),
        },
        {
          indicator: 'OK',
          value: getValueWithTooltip(
            'OK',
            'Inscritas',
            'calculateOKRegistered',
            'calculateTotalRegistered',
            candidates,
            current_date,
            users
          ),
          valueOld: getValueWithTooltip(
            'OK',
            'Inscritas',
            'calculateOKRegistered',
            'calculateTotalRegistered',
            candidatesOld,
            last_date,
          ),
          ratio: getRatioUnsure('calculateOKRegistered'),
        },
        {
          indicator: 'No requisitos',
          value: getValueWithTooltip(
            'No requisitos',
            'Inscritas',
            'calculateNotOKRegistered',
            'calculateTotalRegistered',
            candidates,
            current_date,
            users
          ),
          valueOld: getValueWithTooltip(
            'No requisitos',
            'Inscritas',
            'calculateNotOKRegistered',
            'calculateTotalRegistered',
            candidatesOld,
            last_date
          ),
          ratio: getRatioUnsure('calculateNotOKRegistered'),
        },
      ],
    },
  ];
}

const drawPromoTable = results =>
  results.map(result => (
    <Paper key={result.title} style={{ marginBottom: '1em' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography color="secondary">{result.title}</Typography>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="large">Indicador</TableCell>
            <TableCell className="medium">
              Valor promo <strong>{config.current_promo.toUpperCase()}</strong>
            </TableCell>
            <TableCell className="medium">
              Valor otra promo
              {/* Valor promo <strong>{promoToCompare}</strong> */}
            </TableCell>
            <TableCell className="medium">Desviaciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.data.map(
            ({ indicator, value, valueOld, ratio, important, noIndicator }) => (
              <TableRow
                key={indicator}
                hover
                className={important ? 'highlighted' : ''}
              >
                <TableCell>{indicator}</TableCell>
                <TableCell>{value}</TableCell>
                {
                  noIndicator || (
                    <>
                      <TableCell>{valueOld}</TableCell>
                      <TableCell>{ratio}</TableCell>
                    </>
                  )
                }
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Paper>
  ));

const printDate = date => new Date(date).toLocaleDateString('es');

const getDaysBetweenDates = (first, second) =>
  (second - first) / (1000 * 60 * 60 * 24);

const calculateResultDates = (current, last) => {
  const betweenProcess = getDaysBetweenDates(
    promoDates[current].endOfPrevious,
    promoDates[current].start
  );

  const betweenProcessOld = getDaysBetweenDates(
    promoDates[last].endOfPrevious,
    promoDates[last].start
  );

  const process = getDaysBetweenDates(
    promoDates[current].start,
    promoDates[current].end
  );

  const processOld = getDaysBetweenDates(
    promoDates[last].start,
    promoDates[last].end
  );

  return [
    {
      title: 'FECHAS',
      data: [
        {
          indicator: 'Cierre anterior',
          value: printDate(promoDates[current].endOfPrevious),
          valueOld: printDate(promoDates[last].endOfPrevious),
          ratio: '-',
        },
        {
          indicator: 'Apertura actual',
          value: printDate(promoDates[current].start),
          valueOld: printDate(promoDates[last].start),
          ratio: '-',
        },
        {
          indicator: 'Cierre actual',
          value: printDate(promoDates[current].end),
          valueOld: printDate(promoDates[last].end),
          ratio: '-',
        },
        {
          indicator: 'Días entre procesos',
          value: betweenProcess,
          valueOld: betweenProcessOld,
          ratio: betweenProcess - betweenProcessOld,
        },
        {
          indicator: 'Días proceso de selección',
          value: process,
          valueOld: processOld,
          ratio: process - processOld,
        },
      ],
    },
  ];
};

const promoDates = config.promoDates

// const promoToCompareOptions = ['g', 'f', 'e'];
const promoToCompareOptions = config.data_promos()

const Indicators = ({ candidates, old, users }) => {
  const CURRENT_PROMO = config.current_promo;
  const [promoToCompare, setPromoToCompare] = useState(prev(CURRENT_PROMO));
  const candidatesOld = old
    .filter(c => c.promo === promoToCompare)
    // .filter(c => hasUser(c.id))

  const individualIndicator = getIndividualIndicator(
    candidates,
    candidatesOld,
    promoDates[CURRENT_PROMO],
    promoDates[promoToCompare]
  );
  const withRatioIndicator = getWithRatioIndicator(
    candidates,
    candidatesOld,
    promoDates[CURRENT_PROMO],
    promoDates[promoToCompare]
  );

  const resultsForTable = getResultsForTable(
    individualIndicator,
    withRatioIndicator
  );



  return (
    candidates.length > 0 && (
      <div className="container">
        <Toolbar>
          <FormControl>
            <FormLabel component="legend" color="primary">Comparar con</FormLabel>
            <RadioGroup
              row
              aria-label="comparar" name="comparar"
              value={promoToCompare || ''}
              onChange={event => setPromoToCompare(event.target.value)}
              style={{ display: 'flex' }}
            >

              {promoToCompareOptions.map(option => (
                <FormControlLabel key={option} value={option} control={<Radio color="primary" />} label={config.promos[option]}>
                  {option}
                </FormControlLabel>
              ))}
            </RadioGroup>
          </FormControl>
        </Toolbar>
        {drawPromoTable(calculateResultDates(CURRENT_PROMO, promoToCompare))}

        {drawPromoTable(calculateInscription({
          current: CURRENT_PROMO,
          last: promoToCompare,
          candidates,
          candidatesOld, 
          users,
          current_date: promoDates[CURRENT_PROMO],
          last_date: promoDates[promoToCompare]
        }))}

        {drawPromoTable(resultsForTable)}
      </div>
    )
  );
};

Indicators.propTypes = {
  candidates: PropTypes.array,
  old: PropTypes.array,
};

export default Indicators;
