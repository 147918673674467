import * as KNOWLEDGE from '../constants/prevKnowledge';
import * as STUDIES from '../constants/studies';

const orderCandidates = candidates =>
  candidates.sort(
    (a, b) =>
      new Date(b.inscriptionDate).getTime() -
      new Date(a.inscriptionDate).getTime()
  );

const _calculateMarkForFluidIntelligenceLevel = level => {
  if (!level) return 0;
  if (level === 'Alto' || level === 'Muy Alto') return 4;
  if (level === 'Medio Alto') return 3;
  if (level === 'Medio') return 2;
  if (level === 'Medio Bajo') return 1;
  return 0;
};

const twoDecimalsOf = n => Math.round(n * 100) / 100;

const caculateMarkForBAT7 = ({ BAT7 }) =>
  _calculateMarkForFluidIntelligenceLevel(BAT7['Inteligencia fluida - Nivel']);

const MAX_INTERVIEW_SCORE = 22;
const MAX_SCORE_SCALE = 4;

const calculateNormalizedInterviewScore = interviewScore =>
  twoDecimalsOf((interviewScore * MAX_SCORE_SCALE) / MAX_INTERVIEW_SCORE);

const calculateFinalScore = candidate =>
  twoDecimalsOf(
    calculateProfileScore(candidate) +
      calculateTestsScore(candidate) +
      candidate.interviewScore
  );

const getStudyPoints = studies => {
  if (
    studies.includes('Máster')||
    studies.includes('Postgrado') ||
    studies.toLowerCase() === 'estudios universitarios'
  )
    return 2;
  if (studies === 'FP Superior') return 1;
  return 0;
};

const getPrevKnowledgePoints = prevKnowledge => {
  if (
    prevKnowledge === KNOWLEDGE.FEW_HTML_KNOWLEDGE ||
    prevKnowledge === KNOWLEDGE.DESIGN_KNOWLEDGE
  )
    return 0.5;
  if (
    prevKnowledge === KNOWLEDGE.HTML_KNOWLEDGE ||
    prevKnowledge === KNOWLEDGE.NETWORK_KNOWLEDGE
  )
    return 1;
  if (
    prevKnowledge === KNOWLEDGE.BASIC_PROGRAMMING_KNOWLEDGE ||
    prevKnowledge === KNOWLEDGE.PROGRAMMING_KNOWLEDGE ||
    prevKnowledge === KNOWLEDGE.WEB_KNOWLEDGE
  )
    return 2;
  return 0;
};

const getEnglishPointsFromLevel = level => {
  if (!level) return 0;
  if (level.startsWith('C')) return 1;
  if (level.startsWith('B')) return 0.5;
  return 0;
};

const getBATPoints = bat => {
  if (bat > 3) return 4;
  if (bat === 3) return 3;
  return 0;
};

const calculateTestsScore = candidate => {
  const results =
    candidate.teaResults &&
    candidate.teaResults &&
    candidate.teaResults.BAT7
    ? candidate.teaResults.BAT7['Inteligencia fluida - Nivel'] : 0
  
  const bat = _calculateMarkForFluidIntelligenceLevel(
    results
  );
  return getBATPoints(bat);
};

const calculateProfileScore = ({ englishLevel, studies, prevKnowledge }) =>
  getPrevKnowledgePoints(prevKnowledge) +
  getStudyPoints(studies) +
  getEnglishPointsFromLevel(englishLevel);

const calculateFilter2 = candidate => {
  const { englishScore: score, studies, prevKnowledge, age } = candidate;
  const englishScore = parseInt(score, 10);
  const bat = _calculateMarkForFluidIntelligenceLevel(
    candidate.teaResults.BAT7['Inteligencia fluida - Nivel'] || 0
  );

  const ENGLISH_SCORE_FOR_B1 = 16;
  const ENGLISH_SCORE_MIN = 10;
  const ENGLISH_SCORE_MID = 12;
  const MEDIUM_BAT = 2;
  const MEDIUM_HIGH_BAT = 3;

  // FILTROS TOTALES
  if (bat < MEDIUM_BAT) return 'Nivel de inteligencia';
  if (
    STUDIES.nonHigherStudies.includes(studies)
    && bat < MEDIUM_HIGH_BAT
    && KNOWLEDGE.lowPrevKnowledge.includes(prevKnowledge)
  ) return 'Sin estudios superiores e inteligencia baja'
  if (
    bat < MEDIUM_HIGH_BAT &&
    KNOWLEDGE.lowPrevKnowledge.includes(prevKnowledge) &&
    englishScore <= ENGLISH_SCORE_MIN
  ) return 'Conocimientos previos, inteligencia e inglés bajos'

  // WARNINGS
  if (
    STUDIES.nonHigherStudies.includes(studies) &&
    KNOWLEDGE.lowPrevKnowledge.includes(prevKnowledge) &&
    englishScore < ENGLISH_SCORE_FOR_B1
  )
    return 'Sin estudios superiores, conocimientos previos bajos e inglés bajo - WARNING';

  if (
    bat < MEDIUM_HIGH_BAT &&
    KNOWLEDGE.lowPrevKnowledge.includes(prevKnowledge) &&
    englishScore < ENGLISH_SCORE_FOR_B1
  )
    return 'Inteligencia baja, conocimientos previos bajos e inglés bajo - WARNING';

  if (englishScore < ENGLISH_SCORE_MID)
    return 'Nivel de inglés A1.1 A1.2 - WARNING';
  if (age > 34) return 'Edad mayor que 34 - WARNING';
  return null;
};

export default {
  orderCandidates,
  caculateMarkForBAT7,
  calculateNormalizedInterviewScore,
  calculateFinalScore,
  calculateProfileScore,
  calculateTestsScore,
  calculateFilter2,
  getBATPoints,
};
