export const SECONDARY = 'ESO';
export const BACCALAUREATE = 'Bachillerato';
export const VOCATIONAL_BASIC = 'FP Básica';
export const VOCATIONAL_MEDIUM = 'FP Media';
export const VOCATIONAL_SUPERIOR = 'FP Superior';
export const UNIVERSITY = 'Estudios universitarios';
export const POSTGRADE = 'Postgrado';

export const nonHigherStudies = [
  SECONDARY,
  BACCALAUREATE,
  VOCATIONAL_BASIC,
  VOCATIONAL_MEDIUM,
];

export const universityStudies = [UNIVERSITY, POSTGRADE];
