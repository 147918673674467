import React from 'react';
import { withRouter } from 'react-router-dom';

import * as routes from '../constants/routes';
import SignOutButton from './account/SignOut';
import AuthUserContext from './auth/AuthUserContext';

import {AppBar, Toolbar, Typography, IconButton, MenuItem, Menu} from '@material-ui/core';
import { AccountCircle, Home } from '@material-ui/icons';

const Navigation = (props) =>
  <AuthUserContext.Consumer>
    { authUser => <NavigationAuth authUser={authUser} {...props} />
    }
  </AuthUserContext.Consumer>

class NavigationAuth extends React.Component{
  state = {
    anchorEl: null
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLink = (to) => {
    return () => {
      this.props.history.push(to);
      this.setState({ anchorEl: null });
    }
  };

  render() {
    const { anchorEl } = this.state;
    const { authUser } = this.props;
    const open = Boolean(anchorEl);
    return (
      <AppBar position="static">
        <Toolbar className="NavBar">
          <IconButton className="menuButton" color="inherit" aria-label="Menu"  onClick={this.handleLink(routes.HOME)}>
          <Home />
          </IconButton>
          <Typography variant="title" color="inherit" className="grow">
            Candidatas Adalab
          </Typography>
          <IconButton
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
          >
          {
            authUser?
            (
              [<MenuItem onClick={this.handleLink(routes.ACCOUNT)} key="account">
                Cuenta
              </MenuItem>,
              <MenuItem onClick={this.handleClose} key="signout">
                <SignOutButton />
              </MenuItem>]
            ) : 
            <MenuItem onClick={this.handleLink(routes.SIGN_IN)}>
              Sign In
            </MenuItem>
          } 
          </Menu>
        </Toolbar>
      </AppBar>
    )
  }
} 

export default withRouter(Navigation);