import stringify from 'csv-stringify/lib/sync';
import { saveAs } from 'file-saver';
import { default as processor } from '../process/';

const keepFields = (fieldsToKeep, fieldsToCount, list) =>
  list.map(item => {
    const reduced = {};
    fieldsToKeep.forEach(field => {
      if (item[field]) reduced[field] = item[field];
    });
    fieldsToCount.forEach(field => {
      if (item[field]) reduced[field] = item[field].length;
    });
    return reduced;
  });

const processCandidate = candidate => ({
  Promo: candidate.promo,
  Nombre: candidate.firstName,
  Apellidos: candidate.lastName,
  Modalidad: candidate.assignedSchedule,
  Inicio: candidate.modalidadInicial ? candidate.modalidadInicial  : '',
  Email: candidate.email,
  Teléfono: candidate.phone,
  Dirección: candidate.fullAddress,
  'CP residencia': candidate.zip,
  Ciudad: candidate.city,
  DNI: candidate.dni,
  Nacionalidad: candidate.nationality,
  'Fecha de nacimiento': candidate.birdthDate,
  Edad: candidate.age,
  'Tiene hijos': candidate.hasChildren,
  'Nivel de Estudios': candidate.studies,
  'Detalle estudios': candidate.studiesDescription,
  'Conocimientos de programación': candidate.prevKnowledge,
  'Inglés': candidate.englishLevel,
  'Situación laboral': candidate.workStatus,
  'Necesita ordenador': candidate.useLaptop,
  'Cómo has conocido Adalab': candidate.referral,
  
  'Nota inteligencia fluida': candidate.teaResults
    ? processor.calculateTestsScore(candidate)
    : '',
  'Nota perfil': candidate.englishLevel
    ? processor.calculateProfileScore(candidate)
    : '',
  'Nota entrevista': candidate.interviewScore,
  'Nota final': candidate.teaResults
    ? processor.calculateFinalScore(candidate)
    : '',

  'Inteligencia fluida - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['Inteligencia fluida - Nivel'] : '',
  'Inteligencia fluida': candidate.teaResults ? candidate.teaResults.BAT7['Inteligencia fluida'] : '',
  
  'Capacidad general': candidate.teaResults ? candidate.teaResults.BAT7['Capacidad general'] : '',
  'Aptitud numérica': candidate.teaResults ? candidate.teaResults.BAT7['Aptitud numérica'] : '',
  'Aptitud verbal': candidate.teaResults ? candidate.teaResults.BAT7['Aptitud verbal'] : '',
  'Atención': candidate.teaResults ? candidate.teaResults.BAT7['Atención'] : '',
  'Concentración': candidate.teaResults ? candidate.teaResults.BAT7['Concentración'] : '',
  'Razonamiento': candidate.teaResults ? candidate.teaResults.BAT7['Razonamiento'] : '',
  'BAT4 - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['BAT4'] : '',
  'Notas ADALAB entrevista': candidate.interviewFeedback,
  'Comunicación': candidate.interviewB5Q1,
  'Personalidad de la candidata': candidate.personality

  /* 
  BAT: candidate.teaResults
    ? processor.caculateMarkForBAT7(candidate.teaResults)
    : '',

  'BAT: Aptitud numérica - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['Aptitud numérica'] : '',
  'BAT: Aptitud numérica - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['Aptitud numérica - Nivel'] : '',
  
  'BAT: Aptitud verbal - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['Aptitud verbal'] : '',
  'BAT: Aptitud verbal - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['Aptitud verbal - Nivel'] : '',

  'BAT: Atención - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['Atención'] : '',
  'BAT: Atención - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['Atención - Nivel'] : '',

  'BAT: BAT4 - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['BAT4'] : '',
  'BAT: BAT4 - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['BAT4 - Nivel'] : '',

  'BAT: Capacidad general - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['Capacidad general'] : '',
  'BAT: Capacidad general - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['Capacidad general - Nivel'] : '',

  'BAT: Concentración - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['Concentración'] : '',
  'BAT: Concentración - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['Concentración - Nivel'] : '',

  'BAT: Razonamiento - NOTA': candidate.teaResults ? candidate.teaResults.BAT7['Razonamiento'] : '',
  'BAT: Razonamiento - NIVEL': candidate.teaResults ? candidate.teaResults.BAT7['Razonamiento - Nivel'] : '',

  'Preferencia de horario': candidate.preferredScheduleAfterInterview,
  */
});

export const download = (
  list,
  fileName,
  fieldsToKeep,
  fieldsToCount = []
) => () => {
  const options = { header: true, };
  const data = fieldsToKeep
    ? keepFields(fieldsToKeep, fieldsToCount, list)
    : list.map(processCandidate);

  const string = stringify(data, options);
  var file = new File([string], `${fileName}.csv`, {
    type: 'text/csv;charset=utf-8',
  });
  saveAs(file);
};
