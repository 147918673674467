import React from 'react';

import * as Interview from '../../process/interview';
import * as ADMISSION from '../../constants/admission';
import { preAdmissionOptions } from '../../constants/admission';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const drawQuestion = handleChange => (question, number, blockNumber, data) => (
  <React.Fragment key={`question-${blockNumber}-${number}`}>
    <Typography variant="body1" gutterBottom>
      {question}
    </Typography>
    <Select
      value={data[`interviewB${blockNumber}Q${number}`] || ''}
      onChange={handleChange}
      inputProps={{
        name: `interviewB${blockNumber}Q${number}`,
        id: `interviewB${blockNumber}Q${number}`,
      }}
      className="select"
    >
      <MenuItem value="OK">OK</MenuItem>
      {blockNumber === 2 ? null : (
        <MenuItem value="Más o menos">Más o menos</MenuItem>
      )}
      <MenuItem value="No">No</MenuItem>
    </Select>
  </React.Fragment>
);

const feedbackStructureDefault = Interview.interviewQuestions
  .map(q => q.title.toUpperCase())
  .join('\n\n\n')
  .concat('\n\n\nGLOBAL\n');

const preferredScheduleAfterInterviewOptions = [
  'Empezar presencial',
  'Empezar online',
];

const personalityOptions = ['Introvertida', 'Extrovertida'];

class InterviewResults extends React.Component {
  render() {
    const {
      interviewFeedback,
      preferredScheduleAfterInterview,
      personality,
      preAdmission,
      doUpdate,
      handleChange,
      referral,
      referralAlt,
      referralMarketing,
      referralAltMarketing,
      preferredSchedule,
    } = this.props;

    const {
      resultsPerBlock,
      results,
      finalResult,
    } = Interview.calculateAllResults(this.props);

    const createQuestion = drawQuestion(handleChange);

    return (
      <>
        <fieldset>
          <legend>Notas entrevista</legend>
          <FormControl fullWidth>
            <Typography variant="body1" gutterBottom>
              Pre-admisión
            </Typography>
            <Select
              value={preAdmission || ''}
              onChange={handleChange}
              inputProps={{
                name: 'preAdmission',
                id: 'preAdmission',
              }}
              className="select"
            >
              {preAdmissionOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="interviewFeedback" className="input--label">
              Comentarios entrevista
            </InputLabel>
            <Input
              id="interviewFeedback"
              name="interviewFeedback"
              className="input"
              variant="outlined"
              placeholder="La candidata me ha parecido..."
              multiline={true}
              fullWidth
              rows={20}
              value={interviewFeedback || feedbackStructureDefault}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <Typography variant="body1" gutterBottom>
              ¿Cómo nos ha conocido?
            </Typography>
            <Select
              value={referral || ''}
              onChange={handleChange}
              inputProps={{
                name: 'referral',
                id: 'referral',
              }}
              className="select"
            >
              {[
                'Instagram',
                'LinkedIn',
                'Twitter',
                'Facebook',
                'Anuncio en Google',
                'Búsqueda en Internet',
                'Boca a boca',
                'Email',
                'Medios de comunicación (prensa, radio, etc.)',
                'Otro medio',
              ].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <Typography variant="body1" gutterBottom>
              Más información sobre como nos conoció
            </Typography>
            <Input
              id="referralAlt"
              name="referralAlt"
              className="input"
              variant="outlined"
              multiline={true}
              fullWidth
              value={referralAlt}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl>
            <Button
              variant="outlined"
              onClick={doUpdate(
                'progress',
                [
                  ...Interview.questionNames,
                  'interviewFeedback',
                  'referral',
                  'referralAlt',
                  'referralMarketing',
                  'referralAltMarketing',
                ],
                {
                  interviewScore: finalResult,
                  preferredScheduleAfterInterview:
                    preferredScheduleAfterInterview || '',
                  preAdmission: preAdmission || '',
                }
              )}
            >
              Guardar Datos Entrevista
            </Button>
          </FormControl>
        </fieldset>
      </>
    );
  }
}

export default InterviewResults;
