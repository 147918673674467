import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import qs from 'qs';

import Table from './Table';
import * as STATUS from '../../constants/status';
import * as PROGRESS from '../../constants/progress';
import * as ADMISSION from '../../constants/admission';
import { download } from '../../csv/';

import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FileIcon from '@material-ui/icons/FileCopy';
import config from '../../config';

const initialState = {
  query: '',
  status: '',
  progress: '',
  admission: '',
  date: '',
  datetime: '',
};

const getIcon = status => React.createElement(STATUS.ICONS[status]);

const filterCandidates = (filters, candidates) => {
  const { query, status, progress, admission, date, datetime, promo } = filters;
  if (!candidates) return [];
  return candidates
    .filter(
      c =>
        query === '' ||
        (c.firstName && c.firstName.toLowerCase().includes(query)) ||
        (c.lastName && c.lastName.toLowerCase().includes(query)) ||
        (c.email && c.email.toLowerCase().includes(query))
    )
    .filter(c => !status || (c.status && c.status === status))
    .filter(c => !progress || (c.progress && c.progress === progress))
    .filter(c => !admission || (c.admission && c.admission === admission))
    .filter(
      c =>
        !promo ||
        (c.promo &&
          (promo === 'futuras'
            ? c.promo !== config.current_promo && c.promo !== 'por definir'
            : c.promo === promo))
    )
    .filter(
      c =>
        !date ||
        (c.interviewDate &&
          new Date(c.interviewDate).toISOString().includes(date))
    )
    .filter(
      c =>
        !datetime ||
        (c.testsDate &&
          new Date(c.testsDate)
            .toLocaleString('es-ES')
            .includes(new Date(datetime).toLocaleString('es-ES')))
    );
};

class SearchableTable extends React.Component {
  constructor(props) {
    super(props);

    const params = qs.parse(this.props.location.search.slice(1));

    this.state = {
      ...initialState,
      ...params,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;

    const updatedValue = name === 'query' ? value.toLowerCase() : value;
    this.props.history.push({
      search: qs.stringify({
        ...this.state,
        [name]: updatedValue,
      }),
    });
    this.setState({ [name]: updatedValue });
  };

  resetFilters = event => {
    event.preventDefault();
    this.setState(initialState);
    this.props.history.push({
      search: qs.stringify(initialState),
    });
  };

  render() {
    const {
      query,
      status,
      progress,
      admission,
      date,
      datetime,
      promo,
    } = this.state;

    const candidates = filterCandidates(this.state, this.props.candidates);

    return (
      <div>
        <form action="" className="search-form">
          <TextField
            label="Nombre o email"
            name="query"
            value={query}
            onChange={this.handleChange}
            placeholder="Busca…"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl>
            <InputLabel shrink htmlFor="status" className="select--label">
              Estado
            </InputLabel>
            <Select
              value={status}
              onChange={this.handleChange}
              inputProps={{
                name: 'status',
                id: 'status',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {STATUS.ALL.map(state => (
                <MenuItem
                  value={state}
                  key={state}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {state} {getIcon(state)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel shrink htmlFor="progress" className="select--label">
              Progreso
            </InputLabel>
            <Select
              value={progress}
              onChange={this.handleChange}
              inputProps={{
                name: 'progress',
                id: 'progress',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {PROGRESS.ALL.map(p => (
                <MenuItem value={p} key={p}>
                  {p}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel shrink htmlFor="admission" className="select--label">
              Admisión
            </InputLabel>
            <Select
              value={admission}
              onChange={this.handleChange}
              inputProps={{
                name: 'admission',
                id: 'admission',
              }}
              className="select"
              displayEmpty
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {ADMISSION.ALL.map(a => (
                <MenuItem value={a} key={a}>
                  {a}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Fecha entrevista"
            type="date"
            name="date"
            value={date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Fecha pruebas presenciales"
            type="datetime-local"
            name="datetime"
            value={datetime}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <IconButton aria-label="Refresh" onClick={this.props.loadCandidates}>
            <RefreshIcon />
          </IconButton>
          <Button variant="outlined" onClick={this.resetFilters}>
            Borrar Filtros
          </Button>
          <IconButton
            aria-label="Download"
            onClick={download(candidates, 'candidates')}
          >
            <FileIcon />
          </IconButton>
        </form>
        {!!candidates && <Table candidates={candidates} old={this.props.old} />}
      </div>
    );
  }
}

SearchableTable.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  candidates: PropTypes.array,
  old: PropTypes.bool,
  loadCandidates: PropTypes.func,
};

export default withRouter(SearchableTable);
