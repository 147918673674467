import React from 'react';

import * as REFERRAL_TYPES from '../../constants/referrals';

import { Grid, TextField, Typography, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

class Referrals extends React.Component {
  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      fromDate:''
    }
  }

  handleChange(event){
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render(){
    const {fromDate} = this.state;
    const candidates = this.props.candidates.filter(c => !fromDate || (new Date(c.inscriptionDate)).getTime() > (new Date(fromDate)).getTime() );
    return (<div className="container">
      <Grid container  spacing={2}>
        <Grid item xs={4}>
          <form>
            <TextField
              label="Referrals desde el"
              type="date"
              name="fromDate"
              value={fromDate} 
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="title" gutterBottom align="center">
            De un total de {candidates.length}
          </Typography>
          <List>
            {
            REFERRAL_TYPES.ALL.map(ref => 
              <ListItem key={ref}>
                <ListItemText 
                  primary={ref}
                  secondary={candidates.filter(candidate => candidate.referral === ref).length}
                />
                <ListItemSecondaryAction>
                  {(candidates.filter(candidate => candidate.referral === ref).length/candidates.length * 100).toFixed(2)}%
                </ListItemSecondaryAction>
              </ListItem>
            )
            }
          </List>
        </Grid>
      </Grid>
    </div>);
  }
} 

export default Referrals;