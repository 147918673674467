import { db, functions } from './firebase';
import config from '../config';
import { next } from '../helpers';

const candidatesCollectionGroup = db.collectionGroup('candidates');
const candidatesCollection = db.collection('candidates');

export const updateCandidate = (id, field) => {
  const cleanFields = Object.fromEntries(
    Object.entries(field).filter(([key, val]) => val !== undefined)
  );

  return candidatesCollection
    .doc(id)
    .set(cleanFields, { merge: true })
    .then(() => getCandidate(id));
};

export const getAllUsers = async () => {
  const getUsers = functions.httpsCallable('getUsers');
  const users = await getUsers();
  return users;
};

export const getAllCandidates = async old => {
  const validPromos = [next(config.current_promo), config.current_promo];

  const filterCandidatesByPromo = promo =>
    old ? !validPromos.includes(promo) : validPromos.includes(promo);

  const snapshot = await candidatesCollectionGroup.get();
  return snapshot.docs
    .filter(doc => filterCandidatesByPromo(doc.data().promo))
    .map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
};

export const getAllActiveCandidates = async () => {
  const indexCurrentPromo = config.allPromos.findIndex(
    i => i === config.current_promo
  );
  const futurePromos = [
    ...config.allPromos.slice(indexCurrentPromo),
    'por definir',
  ];

  const candidates = await db
    .collection('candidates')
    .get()
    .then(snapshot => {
      return snapshot.docs
        .map(doc => ({
          ...doc.data(),
          id: doc.id,
        }))
        .filter(ada => futurePromos.includes(ada.promo));
    });
  return candidates;
};

export const getCandidate = id =>
  candidatesCollection
    .doc(id)
    .get()
    .then(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

export const getCandidateNu = id =>
  candidatesCollectionGroup
    .doc(id)
    .get()
    .then(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
