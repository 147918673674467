export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';

export const HOME = '/';
export const SUMMARY = '/summary';
export const INDICATORS = '/indicators';
export const REFERRALS = '/referrals';

export const CANDIDATES = '/candidates';
export const RANKING = '/ranking';
export const OLD = '/old';
export const ADMITTED = '/admitted';
export const ADMITTED_CONFIRMED = '/admitted_confirmed';
