import {
  Done as PreOKIcon,
  DoneAll as OKIcon,
  ReportProblem as AvailabilityIcon,
  ChildCare as AgeIcon,
  School as StudiesIcon,
} from '@material-ui/icons';

export const STARTED = 'Comenzado';
export const TEST_TECNICO = 'HTML';
export const PROCTORING = 'Tests inglés y psicométricos';
export const INTERVIEW = 'Entrevista';
export const INTERVIEW_COMPLETED = 'Entrevista completada';
export const PROCESS_COMPLETED = 'Proceso completado';
export const LEFT_PROCESS = 'Abandona el proceso';

export const ALL_ACTIVE = [
  STARTED,
  TEST_TECNICO,
  PROCTORING,
  INTERVIEW,
  INTERVIEW_COMPLETED,
  PROCESS_COMPLETED,
  LEFT_PROCESS,
];



export const ICONS = {
  [TEST_TECNICO]: OKIcon,
  [PROCTORING]: PreOKIcon,
  [INTERVIEW]: AvailabilityIcon,
  [INTERVIEW_COMPLETED]: AgeIcon,
  [LEFT_PROCESS]: StudiesIcon,
};

export const ALL = ALL_ACTIVE;
