import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { auth } from '../../firebase';
import * as routes from '../../constants/routes';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';

import './SignIn.css';

const SignInPage = ({ history }) =>
  <div className="container layout">
    <Paper className="paper">
      <Avatar>
        <LockIcon />
      </Avatar>
      <Typography variant="headline" gutterBottom align="center">
        Sign In
      </Typography>
      <SignInForm history={history} />
    </Paper>
  </div>

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push(routes.HOME);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <form>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={event => this.setState(byPropKey('email', event.target.value))}
          placeholder="ines@adalab.es"
        />
        <TextField
          fullWidth
          label="Password"
          value={password}
          onChange={event => this.setState(byPropKey('password', event.target.value))}
          type="password"
          placeholder="secret"
        />
        <Button 
          fullWidth
          variant="raised"
          color="primary"
          disabled={isInvalid}
          onClick={this.onSubmit}
          style={{marginTop: 32}}
        >
          Sign In
        </Button>
        { error && <Typography variant="body1" gutterBottom>{error.message}</Typography> }
      </form>
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};