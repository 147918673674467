import React from 'react';
import PropTypes from 'prop-types';

import { Switch, Route, withRouter } from 'react-router-dom';
import * as routes from '../constants/routes';

import withAuthorization from './auth/withAuthorization';
import SearchableTable from './candidates/SearchableTable';
import Summary from './candidates/Summary';
import Indicators from './candidates/Indicators';
import Referrals from './candidates/Referrals';
import CandidateDetail from './candidates/Detail';
import Ranking from './candidates/Ranking';
import Admitted from './candidates/Admitted';

import { db } from '../firebase';
import processor from '../process';

import { Paper, Tabs, Tab } from '@material-ui/core';

const TABS = [
  routes.HOME,
  routes.RANKING,
  routes.SUMMARY,
  routes.INDICATORS,
  routes.REFERRALS,
  routes.OLD,
];

const getTabIndex = route =>
  TABS.indexOf(route) === -1 ? 0 : TABS.indexOf(route);

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.loadCandidates = this.loadCandidates.bind(this);
    this.loadOldCandidates = this.loadOldCandidates.bind(this);

    this.state = {
      candidates: [],
      old: [],
      tabIndex: getTabIndex(this.props.history.location.pathname),
    };
  }

  loadUsers() {
    db.getAllUsers().then(({ data }) => {
      this.setState({
        users: data,
      });
      // console.log('users', data)
    });
  }

  loadCandidates() {
    db.getAllActiveCandidates().then(candidates => {
      this.setState({
        candidates: processor.orderCandidates(candidates.filter(c => c.status)),
      });
    });
  }

  loadOldCandidates() {
    db.getAllCandidates(true).then(candidates => {
      this.setState({
        old: processor.orderCandidates(candidates.filter(c => c.status)),
      });
      // console.log('OLD', processor.orderCandidates(candidates.filter(c => c.status)))
    });
  }

  componentDidMount() {
    this.loadCandidates();
    this.loadOldCandidates();
    this.loadUsers();
  }

  handleChange = (event, tabIndex) => {
    this.props.history.push(TABS[tabIndex]);
    this.setState({ tabIndex });
  };

  render() {
    const { candidates, old, users } = this.state;
    return (
      <div>
        <Paper square elevation={1}>
          <Tabs
            className="tabs"
            onChange={this.handleChange}
            value={this.state.tabIndex}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Listado candidatas" />
            <Tab label="Ranking" />
            <Tab label="Totales proceso en curso" />
            <Tab label="Indicadores" />
            <Tab label="Referrals" />
            <Tab label="Proceso anterior" />
          </Tabs>
        </Paper>

        <Switch>
          <Route
            exact
            path={routes.HOME}
            render={() => (
              <SearchableTable
                candidates={candidates}
                loadCandidates={this.loadCandidates}
              />
            )}
          />
          <Route
            exact
            path={routes.OLD}
            render={() => <SearchableTable candidates={old} old={true} />}
          />
          <Route
            exact
            path={routes.SUMMARY}
            render={() => <Summary candidates={candidates} />}
          />
          <Route
            exact
            path={routes.INDICATORS}
            render={() => (
              <Indicators candidates={candidates} old={old} users={users} />
            )}
          />
          <Route
            exact
            path={routes.REFERRALS}
            render={() => <Referrals candidates={candidates} />}
          />
          <Route
            exact
            path={routes.ADMITTED}
            render={props => <Admitted candidates={candidates} {...props} />}
          />
          <Route
            exact
            path={routes.ADMITTED_CONFIRMED}
            render={props => <Admitted candidates={candidates} {...props} />}
          />
          <Route
            exact
            path={`${routes.CANDIDATES}/:id`}
            render={({ match }) =>
              old.length > 0 && (
                <CandidateDetail
                  key={match.params.id}
                  id={match.params.id}
                  oldCandidates={old}
                  nuCandidates={candidates}
                />
              )
            }
          />
          <Route
            exact
            path={`${routes.OLD}/:id`}
            render={({ match }) =>
              old.length > 0 && (
                <CandidateDetail
                  key={match.params.id}
                  id={match.params.id}
                  old={true}
                  oldCandidates={this.state.old}
                  nuCandidates={candidates}
                />
              )
            }
          />
          <Route
            exact
            path={routes.RANKING}
            render={() => <Ranking candidates={candidates} old={old} />}
          />
          <Route
            exact
            path={`${routes.RANKING}/:id`}
            render={({ match }) => (
              <Ranking id={match.params.id} candidates={candidates} old={old} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

HomePage.propTypes = {
  history: PropTypes.object,
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withRouter(HomePage));
