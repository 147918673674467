import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as routes from '../../constants/routes';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const TableOfAdmitted = ({ candidates, history, old }) => {
  return (
    <Table className="table">
      <TableHead>
        <TableRow>
          <TableCell>Nombre</TableCell>
          {/* <TableCell>Email</TableCell> */}
          <TableCell>Nacionalidad</TableCell>
          <TableCell>Inteligencia fluida</TableCell>
          <TableCell>Edad</TableCell>
          <TableCell>Conocimientos previos</TableCell>
          <TableCell>Estudios</TableCell>
          <TableCell>Comunicación durante la entrevista</TableCell>
          <TableCell>Horario asignado</TableCell>
          <TableCell>Modalidad de pago</TableCell>
          <TableCell>Portátil</TableCell>
          <TableCell>Aceptación de plaza</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {candidates.map(
          ({
            id,
            firstName,
            email,
            lastName,
            nationality,
            teaResults,
            age,
            prevKnowledge,
            studies,
            interviewB5Q1,
            assignedSchedule,
            modalidadDePago,
            useLaptop,
            acceptanceOption
          }) => (
            <TableRow
              hover
              key={id}
              onClick={() =>
                history.push(`${old ? routes.OLD : routes.CANDIDATES}/${id}`)
              }
            >
              <TableCell>
                {firstName} {lastName}
              </TableCell>
              {/* <TableCell>{email}</TableCell> */}
              <TableCell>{nationality}</TableCell>
              <TableCell>
                {teaResults && teaResults['BAT7']['Inteligencia fluida - Nivel']}
              </TableCell>
              <TableCell>{age}</TableCell>
              <TableCell>{prevKnowledge}</TableCell>
              <TableCell>{studies}</TableCell>
              <TableCell>{interviewB5Q1}</TableCell>
              <TableCell>{assignedSchedule}</TableCell>
              <TableCell>{modalidadDePago}</TableCell>
              <TableCell>{useLaptop}</TableCell>
              <TableCell>{acceptanceOption}</TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
}

TableOfAdmitted.propTypes = {
  history: PropTypes.object,
  candidates: PropTypes.array,
  old: PropTypes.array,
};

export default withRouter(TableOfAdmitted);
