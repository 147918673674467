const deaccent = name => name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const findOldCandidates = (oldCandidates, currentCandidate) => {
  return oldCandidates.filter(old => old.email && old.firstName && old.lastName).filter(
    old => {
      return old.email === currentCandidate.email ||
      (deaccent(old.firstName) === deaccent(currentCandidate.firstName) &&
        deaccent(old.lastName) === deaccent(currentCandidate.lastName))
    }
  );
}