import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import './index.scss';
import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import App from './components/App';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#14d9c4',
      dark: '#099d8d',
    },
    secondary: {
      main: '#54585a',
      light: '#b8b8b9',
    }
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif'
  }
});

ReactDOM.render(<Router>
  <React.Fragment>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </React.Fragment>
</Router>, document.getElementById('root'));