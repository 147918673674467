import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';


const testLocally = true;

const prodConfig = {
  apiKey: 'AIzaSyAViqnBC0UjazkFIQ_DUez5pziq4bMQ0tU',
  authDomain: 'adalab-platform.firebaseapp.com',
  databaseURL: 'https://adalab-platform.firebaseio.com',
  projectId: 'adalab-platform',
  storageBucket: 'adalab-platform.appspot.com',
  messagingSenderId: '283248082932',
};

const devConfig = {
  apiKey: 'AIzaSyDtZ1_txcrbF39AwzKmFu97nfJ9gkAVzjI',
  authDomain: 'adalab-staging.firebaseapp.com',
  databaseURL: 'https://adalab-staging.firebaseio.com',
  projectId: 'adalab-staging',
  storageBucket: '',
  messagingSenderId: '886219310613',
};

// eslint-disable-next-line no-undef
const config = (process.env.NODE_ENV === 'production' || testLocally) ? prodConfig : devConfig;



if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const functions = firebase.functions()
const auth = firebase.auth();
const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);

export { auth, db, functions };
